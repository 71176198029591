import { getToken } from "@/auth/config/keycloak"
import { useMutation } from "@tanstack/react-query"

interface IMutationProps {
    userId: string;
    conversationId: string;
}

interface ITransferConv {
    onSuccess?: (data: any) => void
    onError?: (error: Error) => void
}
const transferConversation = async ({ userId, conversationId }: IMutationProps) => {
    const token = getToken()
    // const formData = new FormData()
    //formData.append("conversationId", conversationId);

    const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_SUPPORT_URL}/support/transfer/${userId}`,
        {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ conversationId }),
        }
    )

    if (!response.ok) {
        throw { status: response.status, response: await response.text() }
    }

    const data = await response.json()
    return data
}

export const useTransferConversation = (options: ITransferConv = {}) => {
    return useMutation({
        mutationFn: transferConversation,
        onSuccess: options.onSuccess,
        onError: options.onError,
    })
}