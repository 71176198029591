export type FSDataType = {
  filters: [[string, string, string]][] | null;
  sort: string[] | null;
};

export function buildFSStr(givenData: FSDataType): string {
  const filterStr = givenData.filters && givenData.filters.length > 0
    ? `filters=${JSON.stringify(givenData.filters)}`
    : null;

  const sortStr = givenData.sort && givenData.sort.length > 0
    ? `sort=${JSON.stringify(givenData.sort)}`
    : null;

  const FSStr = `${filterStr ? '&' + filterStr : ''}${sortStr ? '&' + sortStr : ''}`;

  return FSStr;
}