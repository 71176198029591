import { getToken } from "@/auth/config/keycloak";
import { useQuery } from "@tanstack/react-query";

export const useGetMessages = (conversationId: string, page: number = 0) => {
  const token = getToken();

  const { data, isFetching, error, refetch } = useQuery({
    queryKey: ["messages", conversationId, page], // Add conversationId to queryKey
    queryFn: async () => {
      if (!conversationId) return null; // Prevent query if conversationId is missing
      const url = `${process.env.NEXT_PUBLIC_API_SUPPORT_URL}/support/${conversationId}/messages?page=${page}`;
      const res = await fetch(url, {
        cache: "reload",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!res.ok) {
        throw new Error("Failed to fetch messages");
      }

      const data = await res.json();
      return data;
    },
    enabled: !!conversationId, // Enable query only when conversationId exists
  });

  return {
    data,
    isFetching,
    error,
    refetch,
  };
};