import React from "react";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import {
    Command,
    CommandInput,
    CommandList,
    CommandEmpty,
    CommandGroup,
    CommandItem,
} from "@/components/ui/command";
import { CheckIcon, CaretSortIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import { Input } from "../ui/input";

interface Option {
    label: string;
    value: string;
}

interface AutocompleteDropdownProps {
    options: Option[];
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    dropdownLabel?: string;
    searchText?: string;
    setSearchText: (value: string) => void;
}

const AutocompleteDropdownNew: React.FC<AutocompleteDropdownProps> = ({
    options,
    value,
    onChange,
    placeholder = "Search...",
    dropdownLabel = "Click here to select...",
    searchText,
    setSearchText
}) => {
    const [open, setOpen] = React.useState(false);

    const handleSelect = (selectedValue: string) => {
        if (selectedValue !== value) {
            onChange(selectedValue); // Only change if it's a new selection
        }
        setOpen(false);
    };

    // Ensure there's always a default value
    React.useEffect(() => {
        if (!value && options.length > 0) {
            onChange(options[0].value);
        }
    }, [value, options, onChange]);

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="col-span-3 justify-between overflow-hidden text-ellipsis whitespace-nowrap"
                >
                    {value
                        ? options.find((option) => option.value === value)?.label
                        : dropdownLabel}
                    <CaretSortIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent align="start" className="w-full p-0" onWheelCapture={(e) => e.stopPropagation()}>
                <Command>
                    <Input
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder={placeholder}
                        className="h-9"
                    />
                    <CommandList>
                        <CommandEmpty>No options found.</CommandEmpty>
                        <CommandGroup>
                            {options?.map((option) => (
                                <CommandItem
                                    key={option.value}
                                    value={option.value}
                                    onSelect={() => handleSelect(option.value)}
                                    className="truncate w-[330px]"
                                    title={option.label}
                                >
                                    {option.label}
                                    <CheckIcon
                                        className={cn(
                                            "ml-auto size-4",
                                            value === option.value ? "opacity-100" : "opacity-0"
                                        )}
                                    />
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
};

export default AutocompleteDropdownNew;
