import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { Separator } from "../ui/separator"

export default function DynamicDialog({
  open,
  close,
  label,
  description,
  content,
  handleSubmit,
  customWidth=450
}) {
  return (
    <Dialog open={open} onOpenChange={close}>
      <DialogContent className={`overflow-hidden sm:max-w-[${customWidth}px]`}>
        <DialogHeader>
          <DialogTitle>{label}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        <Separator className="my-2" />
        {content}
        {handleSubmit && (
          <DialogFooter>
            {handleSubmit}
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  )
}