import React, { useEffect, useRef, useState } from "react"

import { Card, CardContent, CardFooter } from "@/components/ui/card"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"

export default function DynamicTabs({ tabs, customWidth=400 }) {
  const [activeTab, setActiveTab] = useState(tabs[0]?.value)
  const tabRefs = useRef({})

  // Function to update the active tab by cycling through the available tabs
  const cycleTabs = () => {
    const currentIndex = tabs.findIndex((tab) => tab.value === activeTab)
    const nextIndex = (currentIndex + 1) % tabs.length
    setActiveTab(tabs[nextIndex].value)
  }

  // Auto-focus on the appropriate input field within each tab's content
  useEffect(() => {
    if (tabRefs.current[activeTab]) {
      tabRefs.current[activeTab].focus()
    }
  }, [activeTab])

  // Handle Ctrl+Q to switch tabs
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && e.key === "t") {
        e.preventDefault()
        cycleTabs()
      }
    }

    document.addEventListener("keydown", handleKeyDown)
    return () => document.removeEventListener("keydown", handleKeyDown)
  }, [activeTab, tabs])

  return (
    <Tabs
      key={activeTab}
      value={activeTab}
      onValueChange={setActiveTab}
      className={`w-[${customWidth}px]`}
    >
      <TabsList className={`grid w-full grid-cols-${tabs.length}`}>
        {tabs.map((tab) => (
          <TabsTrigger key={tab.value} value={tab.value}>
            {tab.label}
          </TabsTrigger>
        ))}
      </TabsList>
      {tabs.map((tab) => (
        <TabsContent key={tab.value} value={tab.value}>
          <Card className="pt-6 mt-6">
            {tab?.content && (
              <CardContent className="space-y-2">
                {/* Render tab content conditionally based on its type */}
                {typeof tab.content === "function"
                  ? tab.content({
                      inputRef: (el) => (tabRefs.current[tab.value] = el),
                    })
                  : tab.content}
              </CardContent>
            )}
            {tab?.footerButton && (
              <CardFooter
                className={`w-full ${tab?.content !== null ? "mt-4" : ""}`}
              >
                {typeof tab.footerButton === "function"
                  ? tab.footerButton({
                      inputRef: (el) => (tabRefs.current[tab.value] = el),
                    })
                  : tab.footerButton}
              </CardFooter>
            )}
          </Card>
        </TabsContent>
      ))}
    </Tabs>
  )
}
