import { getToken } from "@/auth/config/keycloak"
import { useQuery } from "@tanstack/react-query"

export const useGetAllConversationsByManagerId = (
  page: number = 0,
  length: number = 10
) => {
  const token = getToken()

  const { data, isPending, error, refetch } = useQuery({
    queryKey: ["conversations", length, page],
    queryFn: async () => {
      const url = `${process.env.NEXT_PUBLIC_API_SUPPORT_URL}/support/conversations/managers?page=${page}&length=${length}`
      const res = await fetch(url, {
        cache: "no-store",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      if (!res.ok) {
        throw new Error("Failed to fetch integrations")
      }

      const data = await res.json()
      return data
    },
    enabled: !!length,
  })

  return {
    data,
    isPending,
    error,
    refetch,
  }
}
