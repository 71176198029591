import { Button } from "@/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"

export default function TradingPosition({ content }: any) {
    return (
        <div className="flex flex-wrap items-center gap-4">
            <Popover>
                <PopoverTrigger asChild>
                    <Button variant="outline">
                        View Active Position
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-80">
                    {content}
                </PopoverContent>
            </Popover>
        </div>
    )
}