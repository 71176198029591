'use client'

import { useState, useEffect, useRef } from 'react'
import { Input } from "@/components/ui/input"
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"
import { useTheme } from 'next-themes'

interface User {
  id: string
  name: string
  subtitle: string
  avatarUrl: string
  uid: number
}

interface SearchableDropdownProps {
  users: User[]
  onSelect: (userId: string | null) => void
  searchQuery: string
  setSearchQuery: (value: string) => void
  onSearch: (query: string) => void
  selectedUserId: string | null
  setSelectedUserPhone: (phone: string) => void
}

export default function SearchableDropdownNew({
  users,
  searchQuery,
  setSearchQuery,
  onSelect,
  onSearch,
  selectedUserId,
  setSelectedUserPhone
}: SearchableDropdownProps) {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const { theme } = useTheme();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleSelectUser = (userId: string) => {
    onSelect(userId)
    setIsOpen(false)
    const selectedUser = users.find(user => user.id === userId)
    if (selectedUser) {
      setSearchQuery(selectedUser.name)
      setSelectedUserPhone(selectedUser.subtitle)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchQuery(value)
    onSearch(value)
    setIsOpen(true)
    if (value === '') {
      onSelect(null)
    }
  }

  const handleInputFocus = () => {
    setIsOpen(true)
    if (selectedUserId === null && searchQuery === '') {
      onSearch('')
    }
  }

  return (
    <div className="w-full relative" ref={dropdownRef}>
      <Input
        type="search"
        placeholder="Search users..."
        value={searchQuery}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        className="w-full mb-1"
        aria-expanded={isOpen}
        aria-haspopup="listbox"
        aria-controls="user-listbox"
      />
      {isOpen && (
        <div
          id="user-listbox"
          role="listbox"
          className={`absolute w-full mt-1 bg-white border ${theme === "dark" ? "border-gray-700" : "border-gray-200"} rounded-md shadow-lg z-10 max-h-60 overflow-auto scrollbar-thin`}
        >
          {users.length > 0 ? users.map((user) => (
            <div
              key={user.id}
              className={`flex items-center space-x-3 p-2 hover:bg-gray-100 cursor-pointer ${selectedUserId === user.id ? 'bg-blue-200' : ''}`}
              onClick={() => handleSelectUser(user.id)}
              role="option"
              aria-selected={selectedUserId === user.id}
            >
              <Avatar>
                <AvatarImage src={user.avatarUrl} alt={user.name} />
                <AvatarFallback>{user.name.charAt(0)}</AvatarFallback>
              </Avatar>
              <div>
                <p className="text-sm font-medium text-gray-900">{user.name} {user?.uid > 0 ? `(${user.uid})` : ''}</p>
                <p className="text-xs text-gray-500">{user.subtitle}</p>
              </div>
            </div>
          )) : (
            <div className="p-2">
              <p className="text-sm text-gray-500">No users found</p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}