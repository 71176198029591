import Keycloak, {
  KeycloakAdapter,
  KeycloakLogoutOptions,
  KeycloakRegisterOptions,
} from "keycloak-js"

const keycloakConfig = {
  url: "https://accounts.dreamemirates.com",
  realm: "dreamemirates",
  clientId: "onchat-production",
}

let keycloak: Keycloak

if (typeof window !== "undefined") {
  keycloak = new Keycloak(keycloakConfig)
}

let isInitialized = false

export const initKeycloak = async () => {
  if (isInitialized && keycloak)
    return Promise.reject(new Error("Keycloak is already initialized"))

  let isAuthenticated = false

  const checkKeycloakDatas: {
    token: string
    refreshToken: string
    idToken: string
  } | null = localStorage.getItem("keycloakDatas")
    ? JSON.parse(localStorage.getItem("keycloakDatas") ?? "")
    : null

  let initOptions = {}

  if (!checkKeycloakDatas) {
    initOptions = {
      onLoad: "check-sso",
      silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`,
    }
  } else {
    initOptions = {
      token: checkKeycloakDatas.token,
      refreshToken: checkKeycloakDatas.refreshToken,
      idToken: checkKeycloakDatas.idToken,
    }
  }

  isInitialized = true

  await keycloak
    .init(initOptions)
    .then(async (authenticated) => {
      if (!authenticated) {
        checkKeycloakDatas && localStorage.removeItem("keycloakDatas")

        if (!checkKeycloakDatas) {
          await keycloak.login({ scope: "openid profile email phone" })
        } else if (keycloak && !keycloak.isTokenExpired()) {
          await keycloak.updateToken(2 * 3600)
        } else {
          await updateToken()
        }
      }

      localStorage.setItem(
        "keycloakDatas",
        JSON.stringify({
          token: keycloak.token,
          refreshToken: keycloak.refreshToken,
          idToken: keycloak.idToken,
        })
      )

      isAuthenticated = authenticated

      // **Add listener to handle token expiration**
      keycloak.onTokenExpired = async () => {
        console.log("Keycloak token expired")
        await customLogout() // Call custom logout logic
      }
    })
    .catch((err) => {
      isInitialized = false
      console.log("Failed to initialize Keycloak", err)
      logout()
      throw err
    })

  return new Promise((resolve, reject) => {
    const checkInterval = 100
    const timeout = 10000
    let elapsed = 0

    const intervalId = setInterval(() => {
      if (isAuthenticated) {
        clearInterval(intervalId)
        return resolve(isAuthenticated)
      }

      elapsed += checkInterval

      if (elapsed >= timeout) {
        clearInterval(intervalId)
        return reject(isAuthenticated)
      }
    }, checkInterval)
  })
}

export const customLogout = async () => {
  console.log("Custom logout triggered")
  await fetch(`${process.env.NEXT_PUBLIC_API_SUPPORT_URL}/support/logout`, {
    method: "POST",
    cache: "no-store",
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userId: keycloak.tokenParsed?.sub }),
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Failed to call support logout API")
    }
    console.log("Support logout successful")
    localStorage.removeItem("keycloakDatas")
    keycloak.logout()
  }).catch((error) => {
    console.error("Error during support logout:", error)
    keycloak.logout()
  })
}


export const logout = () => {
  if (keycloak) {
    localStorage.removeItem("keycloakDatas")
    keycloak.logout()
  }
}

const updateToken = async () => {
  // console.log("Calling Update Token");
  
  if (keycloak.isTokenExpired()) {
    try {
      console.log("Token is expired and refreshing...");
      await keycloak.updateToken(30)
      localStorage.setItem(
        "keycloakDatas",
        JSON.stringify({
          token: keycloak.token,
          refreshToken: keycloak.refreshToken,
          idToken: keycloak.idToken,
        })
      )
    } catch (error) {
      console.error("Failed to refresh the token", error)
      keycloak.logout()
      return null
    }
  }
  return null
}

export const getToken = () => {
  //console.log("Calling Get Token");
  if (keycloak) {
    updateToken()
    return keycloak.token
  }
  return null
}

export { keycloak }
