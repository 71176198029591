import React, { useState, useEffect } from 'react';
import { useOnlyUser } from '@/api_store/queries/user/useOnlyUser';
import { formatTimestamp } from './dateFormatter';

interface TextProcessorProps {
  text: string;
}

export function TextProcessor({ text }: TextProcessorProps) {
  const [processedText, setProcessedText] = useState(text);

  // Extract all UUIDs from the text
  const uuidRegex = /\{\{([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\}\}/g;
  const uuids = Array.from(text.matchAll(uuidRegex), m => m[1]);

  // Fetch user data for all UUIDs
  const userQueries = useOnlyUser(uuids);

  useEffect(() => {
    let newText = text;

    // Replace UUIDs with names
    userQueries.forEach((query, index) => {
      if (query.isSuccess && query.data) {
        const uuid = uuids[index];
        const name = query.data.firstName?.length > 0 ? `${query.data.firstName} ${query.data.lastName}` : query.data.username;
        newText = newText.replace(new RegExp(`\\{\\{${uuid}\\}\\}`, 'g'), name);
      }
    });

    // Replace timestamps with human-readable format
    const timestampRegex = /\{\{(\d+)\}\}/g;
    newText = newText.replace(timestampRegex, (_, timestamp) => formatTimestamp(parseInt(timestamp)));

    setProcessedText(newText);
  }, [text, userQueries, uuids]);

  return <div>{processedText}</div>;
}

