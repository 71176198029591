import Script from "next/script"

const NotFoundAnimation = () => {
  return (
    <>
      <Script
        src="https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs"
        type="module"
      />

      <dotlottie-player
        src="https://lottie.host/81e4568d-68f0-4431-8cf1-67b983cc1d36/miH3ymtGrq.json"
        background="transparent"
        speed="1"
        style={{ width: "200px", height: "200px" }}
        loop
        autoplay
      ></dotlottie-player>
    </>
  )
}

export default NotFoundAnimation
