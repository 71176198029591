import { getToken } from "@/auth/config/keycloak";
import { useKeycloak } from "@/auth/provider/KeycloakProvider";
import { useQueries } from "@tanstack/react-query";

function removePlusFromNumber(phoneNumber: string) {
  return phoneNumber?.replace("+", "");
}

export const useGetVendors = (ids: string[]) => {
  const token = getToken();
  const { tradeToken } = useKeycloak();

  return useQueries({
    queries: ids?.map((id) => ({
      queryKey: ["vendors", id],
      queryFn: async () => {
        try {
          // Fetch user data by ID
          const res = await fetch(
            `${process.env.NEXT_PUBLIC_API_USER_URL}/user/${id}`,
            {
              cache: "no-store",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (!res.ok) {
            throw new Error("Failed to fetch user");
          }

          const vendorData = await res.json();
          const vendor = vendorData?.data?.User;

          // Check if the user has a phone number
          const phone = vendor?.attributes?.phone?.[0] || null;
          let phoneData = null;

          // If phone number exists, fetch additional data by phone
          if (phone) {
            const phoneN = removePlusFromNumber(phone);
            const phoneRes = await fetch(
              `${process.env.NEXT_PUBLIC_API_STAGING}/vendor/external/vendor-by-phone?phone=${phoneN}`,
              {
                cache: "no-store",
                headers: {
                  Authorization: `Bearer ${tradeToken}`,
                  "Content-Type": "application/json",
                },
              }
            );

            if (phoneRes.ok) {
              phoneData = await phoneRes.json();
            } else {
              throw new Error("Failed to fetch vendor by phone");
            }
          }

          // Only return the response once both user and phone fetches are completed
          return {
            ...vendor,
            phoneData, // Add phone data to the user object
          };
        } catch (error) {
          console.error("Error in fetching vendor data:", error);
          throw error;
        }
      },
      enabled: !!id,
    })),
  });
};