"use client"

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useGetAllConversationsByManagerId } from "@/api_store/queries/supports/useConversations"
import { useGetMessages } from "@/api_store/queries/supports/useInboxChunkSupports"
import { useGetUsers } from "@/api_store/queries/user/useUser"
import { useKeycloak } from "@/auth/provider/KeycloakProvider"

import { SidebarInbox } from "../sidebar-inbox"
import { Chat } from "./chat"
import { Sidebar } from "./sidebar"
import { useSocket } from "@/auth/provider/SocketContext"
import { useGetVendors } from "@/api_store/queries/user/useVendor";
import { useConvSeen } from "@/api_store/mutations/managers/useConvSeen"
import { UseQueryResult } from "@tanstack/react-query"

export function convertToTimeFormatGMT6(timestamp: string) {
  const date = new Date(timestamp);

  // Get the current UTC time and add 6 hours for GMT+6
  const gmt6Date = new Date(date.getTime() + 6 * 60 * 60 * 1000);

  let hours = gmt6Date.getUTCHours();
  const minutes = gmt6Date.getUTCMinutes();
  const seconds = gmt6Date.getUTCSeconds();

  // Determine AM or PM
  const ampm = hours >= 12 ? 'pm' : 'am';

  // Convert 24-hour format to 12-hour format
  hours = hours % 12 || 12; // Handle midnight (0 hour) as 12

  // Format with leading zeros for single digits
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return `${formattedHours}.${formattedMinutes} ${ampm}`;
}

export function getLastItems(arr: any) {
  return (arr.length >= 5 ? arr.slice(-5) : arr).reverse();
}

export function ChatLayout() {
  const [conversations, setConversations] = useState<any[]>([])
  const [participantIds, setParticipantIds] = useState<string[]>([])
  const [vendorIds, setVendorIds] = useState<string[]>([])
  const [messages, setMessages] = useState<any[]>([]);
  const [conversationId, setConversationId] = useState('');
  const [mySide, setMySide] = useState("");
  const [newMessageSender, setNewMessageSender] = useState("");
  const [newMessageRecipient, setNewMessageRecipient] = useState("");
  const [isNewMessage, setIsNewMessage] = useState(false);
  const [customerLastMessages, setCustomerLastMessages] = useState<any[]>([]);
  const [userMessages, setUserMessages] = useState<any[]>([]);
  const [isMyConversation, setIsMyConversation] = useState(false);
  const uidCache = useRef(new Map());


  const { data, isPending: isLoadingConversations, refetch } = useGetAllConversationsByManagerId(0, 100);
  // Ref to hold the actual sendMessage function from Chat
  const sendMessageRef = React.useRef<(message: any) => void>();

  const [handleTradeFromMessageRef, setHandleTradeFromMessageRef] = useState<((sTradeId: string) => void) | null>(null);

  const onTradeFromMessage = useCallback((callback: (sTradeId: string) => void) => {
    setHandleTradeFromMessageRef(() => callback);
  }, []);

  const { socket, status } = useSocket();

  const {
    data: messageData,
    isFetching: isMessageDataPending,
    refetch: refetchMessageData,
    error: errorMessageData,
  } = useGetMessages(conversationId);

  const usersQueries: UseQueryResult<any, Error>[] = useGetUsers(participantIds)
  const vendorQueries: UseQueryResult<any, Error>[] = useGetVendors(vendorIds)
  // console.log("usersQueries", usersQueries);
  // console.log("vendorQueries", vendorQueries);

  const [selectedConversation, setSelectedConversation] = useState<any>(null)


  const { user }: any = useKeycloak()
  const userId = user?.userId

  const extractedAgentIds: string[] = useMemo(() => {
    if (messageData) {
      const msgs = messageData?.data?.inboxChunkSupportMessages?.messages;
      const senders = new Set<string>(); // Ensure the Set contains only strings
      if (msgs?.length > 0) {
        msgs.forEach((item: any) => {
          if (item.agentPart !== null) {
            senders.add(item.agentPart.sender);
          }
        });
      }
      return Array.from(senders);
    }
    return [];
  }, [messageData]);

  const agentQueries = useGetUsers(extractedAgentIds);

  const allQueriesCompleted = useMemo(
    () => agentQueries.every((query) => query.isSuccess),
    [agentQueries]
  );

  const isUserOrVendorLoading = useMemo(() => {
    return usersQueries.some((query) => query.isLoading) || vendorQueries.some((query) => query.isLoading);
  }, [usersQueries, vendorQueries]);

  const combinedMessages = useMemo(() => {
    if (messageData && allQueriesCompleted) {
      const msgs = messageData?.data?.inboxChunkSupportMessages?.messages;
      const agentData = agentQueries.map((query) => query.data); // Collect agent data

      let previousAgentInfo: any = null;

      return msgs.map((message: any) => {
        // If agentPart is not null, find agent info, otherwise, use the previous agentInfo
        const agentInfo = message?.agentPart
          ? agentData.find((agent) => agent?.id === message?.agentPart?.sender)
          : previousAgentInfo;

        // Update previousAgentInfo for the next iteration
        if (message?.agentPart) {
          previousAgentInfo = agentInfo;
        }

        console.log("agentInfo",
          {
            id: message.userPart?._id,
            avatar: message.agentPart
              ? agentInfo?.attributes?.profile_photo?.[0]
              : selectedConversation?.attributes?.profile_photo?.[0],
            name: message.userPart?.sender,
            sender: message.userPart?.sender,
            message: message.userPart?.content,
            agent: message.agentPart?.sender,
            sentTime: message.userPart?.sent,
            media: message.userPart?.media,
            messageType: message.userPart?.type,
            isFile: message.userPart?.media?.length > 0,
            context: message.userPart?.context,
            isMySide: message.userPart?.sender !== mySide,
            agentInfo: agentInfo,
            agentType: message.agentPart?.agentType
          }
        );

        return {
          id: message.userPart?._id,
          avatar: message.agentPart
            ? agentInfo?.attributes?.profile_photo?.[0]
            : selectedConversation?.attributes?.profile_photo?.[0],
          name: message.userPart?.sender,
          sender: message.userPart?.sender,
          message: message.userPart?.content,
          agent: message.agentPart?.sender,
          sentTime: message.userPart?.sent,
          media: message.userPart?.media,
          messageType: message.userPart?.type,
          isFile: message.userPart?.media?.length > 0,
          context: message.userPart?.context,
          isMySide: message.userPart?.sender !== mySide,
          agentInfo: agentInfo,
          agentType: message.agentPart?.agentType
        };
      });
    }
    return [];
  }, [messageData, allQueriesCompleted, agentQueries, selectedConversation?.attributes?.profile_photo, mySide]);

  // useEffect(() => {
  //   if (messageData) {
  //     debugger
  //     const msgs = messageData?.data?.inboxChunkSupportMessages?.messages;
  //     const isMyMessage = msgs?.some((item: any) => item?.agentPart?.assignee === userId);
  //     setIsMyConversation(isMyMessage);
  //   }
  // }, [messageData, userId]);

  useEffect(() => {
    setMessages((prevMessages) => {
      // Avoid setting state if the new messages are the same as the previous ones
      if (JSON.stringify(prevMessages) !== JSON.stringify(combinedMessages)) {
        return combinedMessages;
      }
      return prevMessages; // No change, so return previous state
    });
    // console.log("combinedMessages", combinedMessages);
  }, [combinedMessages]);

  useEffect(() => {
    if (data) {
      if (data?.data?.conversations?.length > 0) {
        // console.log("Conv data", data);
        setConversations(data?.data?.conversations)

        const convs = data?.data?.conversations;
        let ids: string[] = convs.map((item: any) => item.userPart.conversation.participants[1].id);
        let vIds: string[] = convs.map((item: any) => item.userPart.conversation.participants[0].id);
        let supportAgentsIds: string[] = convs.map((item: any) => item.agentPart.assignee);
        setParticipantIds([...ids, ...supportAgentsIds]);
        setVendorIds([...vIds]);
      }
    }
  }, [data, userId])


  const [selectedVendor, setSelectedVendor] = useState(vendorQueries[0]?.data)

  useEffect(() => {
    const selectedConv = conversations?.find(b => b?.userPart?.conversation?._id === conversationId);
    const selectedUser = usersQueries.find(a => a?.data?.id === selectedConv?.userPart?.conversation.participants?.[1]?.id)?.data;
    const selectedVendor = vendorQueries.find(a => a?.data?.id === selectedConv?.userPart?.conversation.participants?.[0]?.id)?.data;
    // debugger
    if (!isNewMessage) {
      setSelectedConversation(selectedUser);
      setSelectedVendor(selectedVendor);
    }
  }, [usersQueries, conversationId, vendorQueries, conversations])

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    checkScreenWidth()
    window.addEventListener("resize", checkScreenWidth)

    return () => {
      window.removeEventListener("resize", checkScreenWidth)
    }
  }, [])

  const { mutate, isPending: isLoadingPending } = useConvSeen({
    onSuccess: (data) => {
      if (data?.message == "Events updated successfully") {
        refetch();
        console.log("Message even successful.")
      }
    },
    onError: (error) => {
      console.log("Message Event error", error)
    },
  })

  const handleSeenEvent = () => {
    mutate({
      conversationId,
      event: "seen"
    })
  }

  const handleConversationChange = (conversationId: string, item: any) => {
    setIsNewMessage(false);
    setNewMessageRecipient("");
    setSelectedVendor("");
    setNewMessageSender("");
    setConversationId(conversationId); // Trigger query by changing conversationId
    setMySide(item?.userPart?.conversation?.participants?.[0]?.id);

    const tragetConv = conversations?.find(b => b?.userPart?.conversation?._id === conversationId);
    const isMyMessage = tragetConv?.agentPart?.assignee === userId;
    if (isMyMessage) {
      mutate({
        conversationId,
        event: "seen"
      })
    }

    setIsMyConversation(isMyMessage);
  };

  // Handle sendMessage using the actual sendMessage function from Chat
  const handleSendMessage = (message: any) => {
    if (sendMessageRef.current) {
      sendMessageRef.current(message);  // Calls the actual sendMessage function
    } else {
      console.error("sendMessage function is not yet available");
    }
  };

  const allMessages = useMemo(() => {
    return [...conversations]?.map((item) => {
      const user = usersQueries.find(a => a?.data?.id === item?.userPart?.conversation.participants?.[1].id)?.data;
      const vendor = vendorQueries.find(a => a?.data?.id === item?.userPart?.conversation.participants?.[0].id)?.data;
      const assignee = usersQueries.find(a => a?.data?.id === item?.agentPart?.assignee)?.data;
      const initId = vendor?.phoneData?.data?.init_ID;
      const lastMsgTime = convertToTimeFormatGMT6(item?.userPart?.conversation?.lastMessageTime);
      const unreadMsg = item?.userPart?.conversation?.totalMessages - item?.userPart?.conversation?.participants?.[0]?.info?.seen;
      const uid = user?.phoneData?.data?.user?.uid;

      // Persist uid in cache
      if (user?.id && uid) {
        uidCache.current.set(user.id, uid);
      }

      const cachedUid = uidCache.current.get(user?.id);

      return {
        id: item?.userPart?.conversation?._id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        phone: user?.phone,
        messages: user?.messages ?? [],
        avatar: user?.attributes?.profile_photo?.[0],
        active: item?.userPart?.conversation?._id === conversationId,
        changeConversation: () => handleConversationChange(item?.userPart?.conversation?._id, item),
        initId: `@${initId}`,
        initUid: `@${initId} ${cachedUid}`,
        fullName: user?.firstName + " " + user?.lastName,
        uid: cachedUid,
        assignee: item?.agentPart?.status === "Closed" ? "Closed" : item?.agentPart?.status === "Not Assigned" ? "Not Assigned" : assignee?.firstName + " " + assignee?.lastName,
        lastMsgTime: lastMsgTime,
        unreadMsg: unreadMsg
      };
    });
  }, [conversations, usersQueries, vendorQueries, conversationId, handleConversationChange]);

  const myMessages = useMemo(() => {
    return [...conversations]?.filter(item => item?.agentPart?.assignee === userId)?.map((item) => {
      const user = usersQueries.find(a => a?.data?.id === item?.userPart?.conversation.participants?.[1].id)?.data;
      const vendor = vendorQueries.find(a => a?.data?.id === item?.userPart?.conversation.participants?.[0].id)?.data;
      const assignee = usersQueries.find(a => a?.data?.id === item?.agentPart?.assignee)?.data;
      const initId = vendor?.phoneData?.data?.init_ID;
      const lastMsgTime = convertToTimeFormatGMT6(item?.userPart?.conversation?.lastMessageTime);
      const unreadMsg = item?.userPart?.conversation?.totalMessages - item?.userPart?.conversation?.participants?.[0]?.info?.seen;
      const uid = user?.phoneData?.data?.user?.uid;

      // Persist uid in cache
      if (user?.id && uid) {
        uidCache.current.set(user.id, uid);
      }

      const cachedUid = uidCache.current.get(user?.id);

      return {
        id: item?.userPart?.conversation?._id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        phone: user?.phone,
        messages: user?.messages ?? [],
        avatar: user?.attributes?.profile_photo?.[0],
        active: item?.userPart?.conversation?._id === conversationId,
        changeConversation: () => handleConversationChange(item?.userPart?.conversation?._id, item),
        initId: `@${initId}`,
        initUid: `@${initId} ${cachedUid}`,
        fullName: user?.firstName + " " + user?.lastName,
        uid: cachedUid,
        assignee: item?.agentPart?.status === "Closed" ? "Closed" : item?.agentPart?.status === "Not Assigned" ? "Not Assigned" : assignee?.firstName + " " + assignee?.lastName,
        lastMsgTime: lastMsgTime,
        unreadMsg: unreadMsg
      };
    });
  }, [conversations, usersQueries, vendorQueries, userId, conversationId, handleConversationChange]);

  useEffect(() => {
    if (!socket) return;
    socket.on("support-conversations", (data) => {
      console.log("Enter chatlayout support-conversations socket")
      // Attempt to play the sound
      refetch();
      const socketMessageInfo = data?.InboxChunks?.[0];
      const isMyMessage = myMessages?.some(b => b?.id === data?.conversation?._id);
      if (data?.action === "sent" && socketMessageInfo?.messages?.[0]?.source?.platform === "whatsapp" && isMyMessage) {
        const notificationSound = new Audio('/music/livechatsound.mp3');
        notificationSound.oncanplaythrough = () => console.log("Audio loaded successfully from bottombar.");
        notificationSound.onerror = (error) => console.error("Error loading audio:", error);

        notificationSound.play().catch((error) => {
          console.error("Error playing sound:", error);
        });
      }
    });
    socket.on("conversations", () => {
      console.log("Enter chatlayout conversations socket conv")
      refetch();
    });
    socket.on("support-distribution", (data) => {
      // console.log("support-distribution socket data", data)
      console.log("Enter chatlayout support-distribution socket conv")
      refetch();
      if (data?.action == "distributed") {
        const isMyMessage = data?.InboxChunks?.[0]?.userId === userId;
        setIsMyConversation(isMyMessage);
        if (isMyMessage) {
          const notificationSound = new Audio('/music/livechatsound.mp3');
          notificationSound.oncanplaythrough = () => console.log("Audio loaded successfully from layout.");
          notificationSound.onerror = (error) => console.error("Error loading audio:", error);

          notificationSound.play().catch((error) => {
            console.error("Error playing sound:", error);
          });
        }
      }
    });

    // Clean up event listener
    return () => {
      socket.off("support-conversations");
      socket.off("conversations");
      socket.off("support-distribution");
    };
  }, [socket, conversationId, status, refetch]);


  return (
    <div className="flex h-[calc(100vh-74px)] w-full flex-col md:flex-row">
      <div className={`flex-none`}>
        <Sidebar
          links={allMessages}
          myMessages={myMessages}
          isLoadingConversations={isLoadingConversations}
          isUserOrVendorLoading={isUserOrVendorLoading}
          conversationList={conversations}
          conversationId={conversationId}
          setConversationId={setConversationId}
          setMySide={setMySide}
          setSelectedUser={setSelectedConversation}
          setNewMessageRecipient={setNewMessageRecipient}
          setNewMessageSender={setNewMessageSender}
          setIsNewMessage={setIsNewMessage}
          setSelectedVendor={setSelectedVendor}
          vendorQueries={vendorQueries}
        />
      </div>

      <div className={`grow`}>
        <Chat
          messages={messages}
          selectedUser={selectedConversation}
          isMobile={isMobile}
          mySide={mySide}
          conversationId={conversationId}
          refetchMessages={refetchMessageData}
          setSendMessageRef={(sendMessage: (message: any) => void) => {
            sendMessageRef.current = sendMessage;
          }}
          refetchConv={refetch}
          isMessageDataPending={isMessageDataPending}
          isConversations={conversationId?.length > 0 || isNewMessage}
          newMessageSender={newMessageSender}
          newMessageRecipient={newMessageRecipient}
          setNewMessageRecipient={setNewMessageRecipient}
          setNewMessageSender={setNewMessageSender}
          setIsNewMessage={setIsNewMessage}
          setConversationId={setConversationId}
          handleTradeFromMessage={handleTradeFromMessageRef}
          handleSeenEvent={handleSeenEvent}
          setUserMessages={setCustomerLastMessages}
          isMyConversation={isMyConversation}
          myMessages={myMessages}
        />
      </div>
      {
        (conversationId?.length > 0 || isNewMessage) &&
        <SidebarInbox
          selectedUser={selectedConversation}
          selectedVendor={selectedVendor}
          onSendMessage={handleSendMessage}
          conversationId={conversationId}
          onTradeFromMessage={onTradeFromMessage}
          customerLastMessages={customerLastMessages}
          handleSeenEvent={handleSeenEvent}
          isMyConversation={isMyConversation}
        />
      }
    </div>
  )
}
