interface UserMutationProps {
  token: string
  tradeId: number
  takeProfit: number
  stopLoss: number
  vendorId: number
  livePrice: number
  isCancelSltp?: boolean
}

export const modifyActiveTrade = async ({
  token,
  tradeId,
  takeProfit,
  stopLoss,
  vendorId,
  livePrice,
  isCancelSltp=false
}: UserMutationProps) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_STAGING}/opportunity/virtual-trading/modify-active-trade`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "X-VendorId": `${vendorId}`,
      },
      body: JSON.stringify({
        id: tradeId,
        takeProfit,
        stopLoss,
        cancelSltp: isCancelSltp,
        livePrice,
      }),
    }
  )

  if (!response.ok) {
    throw { status: response.status, response: response.statusText }
  }

  const data = await response.json()
  return data
}
