import { getToken } from "@/auth/config/keycloak"
import { useQuery } from "@tanstack/react-query"

export const useMarketPrice = (
  timeStamp: any
) => {
  const token = getToken()

  const { data, isPending, error, refetch } = useQuery({
    queryKey: ["marketPrice", timeStamp],
    queryFn: async () => {
      const url = `${process.env.NEXT_PUBLIC_API_MARKET_PRICE}/market-price/${timeStamp}`
      const res = await fetch(url, {
        cache: "no-store",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      if (!res.ok) {
        throw new Error("Failed to fetch marketprice")
      }

      const data = await res.json()
      return data
    },
    enabled: Boolean(timeStamp)
  })

  return {
    data,
    isPending,
    error,
    refetch,
  }
}
