import { useQuery } from "@tanstack/react-query";

interface GetBusinessOwenerByManagerProps {
    token: string | null | undefined;
    managerId: string;
}

export const GetBusinessOwenerByManager = ({ token, managerId }: GetBusinessOwenerByManagerProps) => {
    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['business', managerId],
        queryFn: async () => {
            const res = await fetch(`${process.env.NEXT_PUBLIC_API_SUPPORT_URL}/support/managers/${managerId}/manager`, {
                cache: 'no-store',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (!res.ok) {
                throw new Error('Failed to fetch users');
            }

            const data = await res.json();
            return data?.data?.userIds
        },
        // enabled: !!length,
    });

    return {
        data,
        isLoading,
        error,
        refetch
    };
};