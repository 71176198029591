"use client";
import { createContext, useContext, useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";
import { useKeycloak } from "./KeycloakProvider";

interface SocketContextProps {
    socket: Socket | null;
    status: string;
    triggerEvent: (event: string, data?: any) => void;
    disconnectSocket: () => void;
}

const SocketContext = createContext<SocketContextProps | undefined>(undefined);

export const useSocket = () => {
    const context = useContext(SocketContext);
    if (!context) {
        throw new Error("useSocket must be used within SocketProvider");
    }
    return context;
};

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
    const [socket, setSocket] = useState<Socket | null>(null);
    const [status, setStatus] = useState("Initializing...");

    const { token }: any = useKeycloak()
    useEffect(() => {
        // Define the async function inside useEffect
        const initSocket = () => {
            setStatus("Initializing socket...");
            try {
                if (!token) {
                    setStatus("No token provided");
                    return;
                }

                const socketInstance = io(`${process.env.NEXT_PUBLIC_API_SOCKET_URL}/support`, {
                    extraHeaders: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                socketInstance.on("connect", () => {
                    setStatus("Connected");
                    console.log("Connected to socket");
                });

                socketInstance.on("disconnect", () => {
                    setStatus("Disconnected");
                });

                socketInstance.on("connect_error", (err) => {
                    setStatus(`Connection Error (${err.message})`);
                    console.log("Connection error:", err);
                });

                socketInstance.on("error", () => {
                    setStatus("error");
                    console.log("Error");
                });

                setSocket(socketInstance);

                // Clean up on unmount
                return () => {
                    socketInstance.disconnect();
                };

            } catch (error) {
                console.error("Failed to initialize socket:", error);
                setStatus("Initialization failed");
            }
        };

        initSocket(); // Call the async function

        // Cleanup function
        return () => {
            if (socket) {
                socket.disconnect();
            }
        };

    }, [token]); // No dependencies to ensure it runs only once on mount

    const disconnectSocket = () => {
        if (socket) {
          socket.disconnect();
          setStatus("Disconnected");
          console.log("Disconnected from socket");
        }
      };

    const triggerEvent = (event: string, data?: any) => {
        socket?.emit(event, data);
    };

    return (
        <SocketContext.Provider value={{ socket, status, triggerEvent, disconnectSocket }}>
            {children}
        </SocketContext.Provider>
    );
};
