import { getToken } from "@/auth/config/keycloak"
import { useMutation } from "@tanstack/react-query"

interface IMutationProps {
  conversationId: string
}

interface ICloseConv {
  onSuccess?: (data: any) => void
  onError?: (error: Error) => void
}
const closeConversation = async ({ conversationId }: IMutationProps) => {
  const token = getToken()

  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_SUPPORT_URL}/support/${conversationId}/distributions`,
    {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

  if (!response.ok) {
    throw { status: response.status, response: await response.text() }
  }

  const data = await response.json()
  return data
}

export const useCloseConversation = (options: ICloseConv = {}) => {
  return useMutation({
    mutationFn: closeConversation,
    onSuccess: options.onSuccess,
    onError: options.onError,
  })
}
