import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const formatHumanReadableDate = (dateString: string): string => {
  if (dateString === "All" || dateString === "Time") {
    return dateString
  }

  const [day, month, year] = dateString.split("-")
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  return `${parseInt(day)} ${months[parseInt(month) - 1]} ${year}`
}
const dubaiTimeZone = "Asia/Dubai"
export function formatCustomDateTime(dateString: string) {
  const date = new Date(dateString);

  // Format date components in Dubai time
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  }).format(date);

  // Format time components in Dubai time, including seconds
  const formattedTime = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit', // Include seconds here
    hour12: true,
  }).format(date);

  return {
    currentDate: formattedDate,
    currentTime: formattedTime,
  };
}


export const uidconstructor = (init_ID: string, uid: string): string => {
  return `${init_ID}${uid}`
}

export const showComma = (value: number | string): string | void => {
  if (isNaN(Number(value))) {
    console.log("NaN detected!!! Please provide a valid number!")
    return
  }
  return new Intl.NumberFormat("en-US").format(Number(value))
}

export const showTwoDecimals = (value: number): string => {
  return value?.toFixed(2) ?? "0"
}

export const AEDConversionRate = 3.672

export const metalObj = {
  TTB: 116.52,
  KILOBAR: 1000,
  GRAM: 1,
  OUNCE: 31.1034768,
}

export const virtualTradingFeePerGram = +(1 / metalObj.OUNCE).toFixed(5)

export const calculateEffectiveWeight = (buyWeight = 0, sellWeight = 0) => {
  return Math.max(buyWeight, sellWeight)
}

export const calculateTradingFeeInDollar = (
  effectiveWeight: number
): number => {
  return effectiveWeight * virtualTradingFeePerGram
}

interface MarginLimitProps {
  goldPrice: { askPrice: number; bidPrice: number }
  netBuyPrice?: number
  balance?: number
  buyWeight?: number
  sellWeight?: number
  effectiveWeight?: number
}

export const calculateMarginLimit = ({
  //This is the margin limit calculator
  goldPrice,
  netBuyPrice = 0,
  balance = 0,
  buyWeight = 0,
  sellWeight = 0,
  effectiveWeight = 0,
}: MarginLimitProps): number => {
  const netWeight = buyWeight - sellWeight
  const absNetWeight = Math.abs(netWeight)

  if (absNetWeight < 0.009) {
    return 0
  }

  const balanceInDollar = balance / AEDConversionRate
  const calculateTradingFee = calculateTradingFeeInDollar(effectiveWeight)
  const finalBalance = balanceInDollar - calculateTradingFee
  const minWeight = Math.min(buyWeight, sellWeight)

  const marginLimit =
    ((netBuyPrice - finalBalance) * metalObj.OUNCE + // Assuming thresholdPercentage is 0.05, adjust accordingly
      minWeight * (goldPrice.askPrice - goldPrice.bidPrice)) /
    absNetWeight

  return +marginLimit.toFixed(2)
}

interface CurrentValueProps {
  goldPrice: { askPrice: number; bidPrice: number }
  buyWeightOz?: number
  sellWeightOz?: number
}

export const calculateCurrentValueUSD = ({
  goldPrice,
  buyWeightOz = 0,
  sellWeightOz = 0,
}: CurrentValueProps): number => {
  return goldPrice.bidPrice * buyWeightOz - goldPrice.askPrice * sellWeightOz
}

interface ProfitLossProps {
  currentVal?: number
  totalVal?: number
  tradingFee?: number
  ConversionRate?: number
}

//P&L (AED)
export const totalProfitLoss = ({
  currentVal = 0,
  totalVal = 0,
  tradingFee = 0,
  ConversionRate = AEDConversionRate,
}: ProfitLossProps): number => {
  const totalProfitLossUSD = currentVal - totalVal - tradingFee
  return +(totalProfitLossUSD * ConversionRate).toFixed(2)
}
// This is net balance
interface NetBalanceProps {
  balance?: number
  profitLossAed?: number
}

// Net Balance
export const netBalanceCalculation = ({
  balance = 0,
  profitLossAed = 0,
}: NetBalanceProps): number => {
  return balance + profitLossAed
}

export const usdNeededToOpenPerGramTrade = +(136.025177 / metalObj.TTB).toFixed(
  6
)

export const withdawAbleBalanceCalc = (
  balance: number,
  netBalance: number,
  netWeight: number,
  vtBonus: number,
  withdrawRate: number
): number => {
  const balanceAvailable = Math.min(balance, netBalance)
  const balanceNeeded = Math.abs(
    netWeight * usdNeededToOpenPerGramTrade * AEDConversionRate
  )
  const withdrawable = balanceAvailable - balanceNeeded
  const charge = Math.min(vtBonus, withdrawable * withdrawRate)

  return withdrawable - charge
}

export const qtyOnz = (goldGms: number): number => {
  return goldGms / (metalObj.OUNCE * 100)
}

export const totalOnz = (goldGms: number): number => {
  return goldGms / metalObj.OUNCE
}

interface CurrentPriceProps {
  close_rate?: number
  goldGms?: number
}

export const currentPriceUsd = ({
  close_rate = 0,
  goldGms = 0,
}: CurrentPriceProps): number => {
  return (close_rate / metalObj.OUNCE) * goldGms
}

interface SingleTradeProfitLossProps {
  tradeType: "BUY" | "SELL"
  totalUsd?: number
  currentUsd?: number
  tradingFee?: number
  conversionRate?: number
}

export const singleTradeProfitLossCalculation = ({
  tradeType,
  totalUsd = 0,
  currentUsd = 0,
  tradingFee = 0,
  conversionRate = AEDConversionRate,
}: SingleTradeProfitLossProps): number => {
  if (tradeType === "BUY") {
    return (currentUsd - totalUsd - tradingFee) * conversionRate
  } else if (tradeType === "SELL") {
    return (totalUsd - currentUsd - tradingFee) * conversionRate
  }
  return 0
}

const abuDhabiTimeZone = 'Asia/Dubai'; 
  
 export const getAbuDhabiTimeAndDate = () => { 
   const newDate = new Date(); 
   const abuDhabiFormattedTime = new Intl.DateTimeFormat('en-US', { 
     hour: '2-digit', 
     minute: '2-digit', 
     hour12: true, 
     timeZone: abuDhabiTimeZone, 
   }).format(newDate); 
   const abuDhabiFormattedDate = new Intl.DateTimeFormat('en-US', { 
     day: '2-digit', 
     month: 'long', 
     year: 'numeric', 
     timeZone: abuDhabiTimeZone, 
   }).format(newDate); 
  
   return { abuDhabiFormattedTime, abuDhabiFormattedDate }; 
 };
