"use client"
import React, { ReactNode, useCallback, useEffect, useState } from "react"
import { ArrowDownCircle, ArrowUpCircle, FileEditIcon, Loader2 } from "lucide-react"

import { Input } from "../ui/input"
import DynamicTabs from "../custom/dynamic-tabs";
import DynamicDialog from "../custom/dynamic-dialog";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
import AutocompleteDropdown from "../custom/autocomplete-dropdown";
import { GetActiveTrades } from "@/api_store/queries/activTrades/getActiveTrades";
import { useToast } from "../ui/use-toast";
import { useCloseActiveTrade } from "@/api_store/mutations/buyTrades/useCloseActiveTrade";
import { useCloseActiveTradePartial } from "@/api_store/mutations/buyTrades/useCloseActiveTradePartial";
import { useModifyActiveTrade } from "@/api_store/mutations/buyTrades/useModifyActiveTrade";
import { useKeycloak } from "@/auth/provider/KeycloakProvider";
import { AEDConversionRate, cn, formatCustomDateTime } from "@/lib/utils";
import { formatDateTime } from "./chat-list";
import { useMarketPrice } from "@/api_store/queries/marketPrice/useMarketPrice";
// import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
// import { Textarea } from "../ui/textarea";
import { generateAndSendPDF } from "../custom/pdf-generator/generateAndSendPDF";

type Props = {
  userId: number;
  vendorId: number;
  refetchQuery: (refetch: () => Promise<any>) => void;
  onSendMessage: (message: any) => void;
  conversationId: any
  askRate: number;
  bidRate: number;
  onTradeFromMessage: (callback: (sTradeId: string) => void) => void;
  isScreenshotMode: boolean;
  customerLastMessages: any;
  autoStatementStatus: boolean;
  statementRefetch: any;
  handleSendStatement: (file: any) => void;
  userRefetch: () => void
  handleSeenEvent: () => void
  setProfitLoss: any
}

interface TradesType {
  id: number;
  b_s: "Buy" | "Sell";
  qty: number;
  rate: number;
  pnl: number;
  act: ReactNode;
  createdAt: string;
}


const messages = [
  { value: "ms01", label: "Buy confirm please (06.30pm)" },
  { value: "ms02", label: "Sell confirm please (06.32pm)" },
  { value: "ms03", label: "Close confirm please (06.33pm)" },
  { value: "ms04", label: "Pertial close 10 percent (06.34pm)" },
  { value: "ms05", label: "Set rate confirm please (06.35pm)" },
]

const ActiveTrades = ({
  userId,
  vendorId,
  refetchQuery,
  onSendMessage,
  conversationId,
  askRate, bidRate,
  onTradeFromMessage,
  isScreenshotMode,
  customerLastMessages,
  autoStatementStatus,
  statementRefetch,
  handleSendStatement,
  userRefetch,
  handleSeenEvent,
  setProfitLoss
}: Props) => {
  const { tradeToken: token } = useKeycloak();
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState("");
  const [activeData, setActiveData] = useState<TradesType[]>([]);
  const [fullData, setFullData] = useState<any[]>([]);
  const [selectedTradeId, setSelectedTradeId] = useState(0)
  const [selectedQuantity, setSelectedQuantity] = useState(0)
  const [partialQuantity, setPartialQuantity] = useState("")
  const [takeProfit, setTakeProfit] = useState("")
  const [stopLoss, setStopLoss] = useState("")
  const { toast } = useToast()
  const [tradeType, setTradeType] = useState("");
  const [userMessages, setUserMessages] = useState<any[]>([]);
  //Market Price
  const [customerAskrate, setCustomerAskRate] = useState(0);
  const [customerBidrate, setCustomerBidRate] = useState(0);
  // Edit trade
  const [editTradeType, setEditTradeType] = useState("");
  const [editTradeRate, setEditTradeRate] = useState("");
  const [editTradeQuantity, setEditTradeQuantity] = useState("");
  const [editTradeReason, setEditTradeReason] = useState("");

  const [searchValue, setSearchValue] = useState("");


  function convertToUnixTimestamp(isoString: string) {
    const date = new Date(isoString);
    return Math.floor(date.getTime() / 1000);
  }

  const resetMessageSelection = () => {
    setSelectedMessage("");
    setCustomerAskRate(0);
    setCustomerBidRate(0);
  }

  useEffect(() => {
    if (customerLastMessages?.length > 0) {
      const msgsOptions = customerLastMessages?.map((item: any) => ({
        id: item?.id,
        value: convertToUnixTimestamp(item?.sentTime).toString(),
        label: (item?.media?.length > 0 && item?.message?.length <= 0) ? `Media content.. (${formatDateTime(item?.sentTime)})` : `${item?.message} (${formatDateTime(item?.sentTime)})`
      }));
      setUserMessages(msgsOptions);
      // console.log(msgsOptions);
    }
  }, [customerLastMessages])

  // API call here
  const { data: marketPrice, isPending: isLoading } = useMarketPrice(selectedMessage);

  useEffect(() => {
    if (marketPrice) {
      // console.log("Market price data:", data);
      setCustomerAskRate(marketPrice?.data?.ask);
      setCustomerBidRate(marketPrice?.data?.bid);
    }
  }, [marketPrice, selectedMessage])

  const { mutate, isPending: isLoadingPending } = useCloseActiveTrade({
    onSuccess: async (data) => {
      if (data?.status) {

        const message = (selectedMessage?.length > 0) ?
          {
            id: conversationId,
            message: `Your trade #${data?.data?.tradeId} has been closed successfully!\n\nTrade Type: ${data?.data?.tradeType}\nQuantity: ${data?.data?.quantity} ${data?.data?.metalType}\nOpen Rate: $${data?.data?.openRate}\nClose Rate: $${data?.data?.closeRate}\n${data?.data?.netProfit === 0 ? "Equal" : data?.data?.netProfit > 0 ? "Profit" : "Loss"}: ${Math.abs(data?.data?.netProfit)} (AED)`,
            attachments: [],  // If needed, you can add attachments here
            attachImages: [],  // If needed, you can add image attachments here
            conversationId: conversationId,
            contextId: userMessages?.find((a: any) => a.value === selectedMessage)?.id
          }
          : {
            id: conversationId,
            message: `Your trade #${data?.data?.tradeId} has been closed successfully!\n\nTrade Type: ${data?.data?.tradeType}\nQuantity: ${data?.data?.quantity} ${data?.data?.metalType}\nOpen Rate: $${data?.data?.openRate}\nClose Rate: $${data?.data?.closeRate}\n${data?.data?.netProfit === 0 ? "Equal" : data?.data?.netProfit > 0 ? "Profit" : "Loss"}: ${Math.abs(data?.data?.netProfit)} (AED)`,
            attachments: [],  // If needed, you can add attachments here
            attachImages: [],  // If needed, you can add image attachments here
            conversationId: conversationId
          }
        onSendMessage(message);
        handleSeenEvent();
        userRefetch();

        const { data: latestStatementData } = await statementRefetch();
        if (latestStatementData && autoStatementStatus) {
          await generateAndSendPDF(latestStatementData.data, handleSendStatement);
        }

        toast({
          variant: "default",
          className: "bg-green-600 text-white hover:bg-green-700",
          description: "Active trade closed successful!",
        })
        refetch()
        closeEdit()
      }
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        description: "Server error. Please contact support!",
      })
      console.log(error)
    },
  });

  const handleTradeFromMessage = useCallback((sTradeId: any) => {
    const sTrade = fullData?.find((item: any) => item?.id === parseInt(sTradeId));
    if (sTrade) {
      mutate({
        tradeId: parseInt(sTradeId),
        livePrice: sTrade?.b_s === "BUY" ? Number(bidRate) : Number(askRate),
        executedPrice: sTrade?.b_s === "BUY" ? Number(bidRate) : Number(askRate),
        token,
        vendorId
      })
    }
    else {
      toast({
        variant: "destructive",
        description: "Trade not found!",
      })
    }
  }, [fullData, bidRate, askRate, mutate, token, vendorId]);

  useEffect(() => {
    onTradeFromMessage(handleTradeFromMessage);
  }, [onTradeFromMessage, handleTradeFromMessage]);

  const { mutate: mutatePartial, isPending: isLoadingPartial } = useCloseActiveTradePartial({
    onSuccess: async (data) => {
      if (data?.status) {
        const message = (selectedMessage?.length > 0) ?
          {
            id: conversationId,
            message: `Your trade #${data?.data?.tradeId} has been partially closed successfully!\n\nTrade Type: ${data?.data?.tradeType}\nQuantity: ${partialQuantity} ${data?.data?.metalType}\nOpen Rate: $${data?.data?.openRate}\nClose Rate: $${data?.data?.closeRate}\n${data?.data?.netProfit === 0 ? "Equal" : data?.data?.netProfit > 0 ? "Profit" : "Loss"}: ${Math.abs(data?.data?.netProfit)} (AED)`,
            attachments: [],  // If needed, you can add attachments here
            attachImages: [],  // If needed, you can add image attachments here
            conversationId: conversationId,
            contextId: userMessages?.find((a: any) => a.value === selectedMessage)?.id
          }
          : {
            id: conversationId,
            message: `Your trade #${data?.data?.tradeId} has been partially closed successfully!\n\nTrade Type: ${data?.data?.tradeType}\nQuantity: ${partialQuantity} ${data?.data?.metalType}\nOpen Rate: $${data?.data?.openRate}\nClose Rate: $${data?.data?.closeRate}\n${data?.data?.netProfit === 0 ? "Equal" : data?.data?.netProfit > 0 ? "Profit" : "Loss"}: ${Math.abs(data?.data?.netProfit)} (AED)`,
            attachments: [],  // If needed, you can add attachments here
            attachImages: [],  // If needed, you can add image attachments here
            conversationId: conversationId
          };
        onSendMessage(message);
        handleSeenEvent();
        userRefetch();

        const { data: latestStatementData } = await statementRefetch();
        if (latestStatementData && autoStatementStatus) {
          await generateAndSendPDF(latestStatementData.data, handleSendStatement);
        }

        toast({
          variant: "default",
          className: "bg-green-600 text-white hover:bg-green-700",
          description: "Partial close successful!",
        })
        setPartialQuantity("");
        refetch()
        closeEdit()
      }
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        description: "Server error. Please contact support!",
      })
      console.log(error)
    },
  });

  const { mutate: mutateModify, isPending: isLoadingModify } = useModifyActiveTrade({
    onSuccess: (data) => {
      if (data?.status) {
        const message = {
          id: conversationId,
          message: `Your trade #${data?.data?.tradeId} has been modified successfully!\n\nTrade Type: ${data?.data?.tradeType}\nTake Profit: $${!data?.data?.takeProfit ? "N/A" : data?.data?.takeProfit}\nStop Loss: $${!data?.data?.stopLoss ? "N/A" : data?.data?.stopLoss}`,
          attachments: [],  // If needed, you can add attachments here
          attachImages: [],  // If needed, you can add image attachments here
          conversationId: conversationId
        };
        onSendMessage(message);
        handleSeenEvent();
        userRefetch();

        toast({
          variant: "default",
          className: "bg-green-600 text-white hover:bg-green-700",
          description: "SL/TP modified successfully!",
        })
        refetch()
        closeEdit()
      }
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        description: "Server error. Please contact support!",
      })
      console.log(error)
    },
  });

  const { data, isPending, error, refetch } = GetActiveTrades({
    userId, vendorId, token, conversationId
  });

  refetchQuery(refetch);

  useEffect(() => {
    if (data) {
      const dt = data?.data?.result;
      let totalPnl = 0;
      const formatData = dt?.map((item: any) => {
        const TTBToOz = item.quantity * 3.7462040;

        const currentValue = ((item.trade_type === "BUY" ? bidRate : askRate) * TTBToOz);
        const totalValue = item?.executed_trade_open_rate * TTBToOz;

        const tradingFee = 1 * TTBToOz;

        const pnl = ((item.trade_type === "BUY" ? ((currentValue - totalValue) - tradingFee) : ((totalValue - currentValue) - tradingFee)) * AEDConversionRate)
        totalPnl += pnl
        const crtDate = formatCustomDateTime(item?.createdAt)

        return ({
          id: item.id,
          b_s: item.trade_type === "BUY" ? "Buy" : "Sell",
          qty: item.quantity,
          rate: item?.executed_trade_open_rate === null ? 0 : item?.executed_trade_open_rate,
          pnl: pnl,
          createdAt: crtDate?.currentDate + " " + crtDate?.currentTime,
          act: <FileEditIcon onClick={() => {
            setOpenEdit(true);
            setSelectedTradeId(item?.id)
            setSelectedQuantity(item?.quantity)
            setTakeProfit(item?.take_profit === null ? "" : item?.take_profit)
            setStopLoss(item?.stop_loss === null ? "" : item?.stop_loss)
            setTradeType(item?.trade_type);
          }} className="size-4 cursor-pointer" />,
        })
      })
      setFullData(formatData);
      setProfitLoss(totalPnl);

      if (!searchValue) {
        setActiveData(formatData);
      } else {
        handleSearchTrades(searchValue); // Keep filtered data if there's an active search
      }
    }
  }, [data, askRate, bidRate])

  const closeEdit = () => {
    setOpenEdit(false);
    resetMessageSelection()
  }

  const handleCloseActiveTrade = () => {
    mutate({
      tradeId: selectedTradeId,
      livePrice: tradeType === "BUY" ? (selectedMessage?.length > 0 && customerBidrate > 0) ? Number(customerBidrate) : Number(bidRate) : (selectedMessage?.length > 0 && customerAskrate > 0) ? Number(customerAskrate) : Number(askRate),
      executedPrice: tradeType === "BUY" ? (selectedMessage?.length > 0 && customerBidrate > 0) ? Number(customerBidrate) : Number(bidRate) : (selectedMessage?.length > 0 && customerAskrate > 0) ? Number(customerAskrate) : Number(askRate),
      token,
      vendorId
    })
  }

  const handleCloseActivePartialTrade = () => {
    if (Number(partialQuantity) <= 0) {
      toast({
        variant: "destructive",
        description: "Quantity must be greater than 0!",
      })
      return;
    }
    mutatePartial({
      livePrice: tradeType === "BUY" ? (selectedMessage?.length > 0 && customerBidrate > 0) ? Number(customerBidrate) : Number(bidRate) : (selectedMessage?.length > 0 && customerAskrate > 0) ? Number(customerAskrate) : Number(askRate),
      token: token,
      tradeId: Number(selectedTradeId),
      executedPrice: tradeType === "BUY" ? (selectedMessage?.length > 0 && customerBidrate > 0) ? Number(customerBidrate) : Number(bidRate) : (selectedMessage?.length > 0 && customerAskrate > 0) ? Number(customerAskrate) : Number(askRate),
      quantity: Number(partialQuantity),
      vendorId
    })
  }

  const handleModifyActiveTrade = () => {
    if (parseInt(stopLoss) === 0 || parseInt(takeProfit) === 0) {
      toast({
        variant: "destructive",
        description: "Stop Loss and Take Profit can't be 0!",
      })
      return;
    }
    const iSCancel = (stopLoss === "" && takeProfit === "") ? true : false
    mutateModify({
      livePrice: tradeType === "BUY" ? Number(askRate) : Number(bidRate),
      token: token,
      tradeId: Number(selectedTradeId),
      vendorId,
      stopLoss: Number(stopLoss),
      takeProfit: Number(takeProfit),
      isCancelSltp: iSCancel
    })
  }

  const handleSearchTrades = (value: string) => {
    setSearchValue(value); // Store the search input
    const dt = [...fullData];
    if (value === "") {
      setActiveData(dt);
      return;
    }
    const filteredData = dt.filter(
      (item) =>
        item.id.toString().includes(value) ||
        item.b_s.toLowerCase().includes(value.toLowerCase()) ||
        item.qty.toString().includes(value) ||
        item.rate.toString().includes(value)
    );
    setActiveData(filteredData);
  };

  const tabs = [
    {
      value: "close",
      label: "Close",
      content:
        <>
          <div className="grid grid-cols-4 items-center gap-1">
            <Label htmlFor="name" className="text-left">
              Set Time:
            </Label>
            <AutocompleteDropdown
              options={userMessages}
              value={selectedMessage}
              onChange={setSelectedMessage}
              placeholder="Search message"
              dropdownLabel="Select message (optional)"
              setSearchText={() => { }}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-1 pt-4">
            <h3>Quantity: </h3>
            <Input id="quantity" disabled value={selectedQuantity} type="text" className="col-span-3" placeholder="Enter quantity" />
          </div>
        </>,
      footerButton: ({ inputRef }: any) => (
        <Button
          size="lg"
          variant="outline"
          ref={inputRef}
          onClick={handleCloseActiveTrade}
          className="flex w-full flex-row gap-1 border-red-400 hover:bg-red-400/20"
        >
          <h3>Closing Price: </h3>
          <h3 className="text-red-400">{isLoadingPending ? <Loader2 className="w-4 h-4 animate-spin" /> : tradeType === "BUY" ? (selectedMessage?.length > 0 && customerBidrate > 0) ? Number(customerBidrate) : Number(bidRate) : (selectedMessage?.length > 0 && customerAskrate > 0) ? Number(customerAskrate) : Number(askRate)}</h3>

        </Button>
      )
    },
    {
      value: "closep",
      label: "Close(Pertial)",
      content: ({ inputRef }: any) => (
        <>
          <div className="grid grid-cols-4 items-center gap-1">
            <Label htmlFor="name" className="text-left">
              Set Time:
            </Label>
            <AutocompleteDropdown
              options={userMessages}
              value={selectedMessage}
              onChange={setSelectedMessage}
              placeholder="Search message"
              dropdownLabel="Select message (optional)"
              setSearchText={() => { }}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-1 pt-4">
            <h3>Quantity: </h3>
            <Input onKeyDown={(e) => {
              if (openEdit && (e.key === "Enter")) {
                handleCloseActivePartialTrade();
              }
            }} ref={inputRef} value={partialQuantity} onChange={(e) => setPartialQuantity(e.target.value)} id="quantity" type="text" className="col-span-3" placeholder="Enter quantity" />
          </div>
        </>
      ),
      footerButton:
        <Button
          size="lg"
          variant="outline"
          onClick={handleCloseActivePartialTrade}
          className="flex w-full flex-row gap-1 border-red-400 hover:bg-red-400/20"
        >
          <h3>Closing Price: </h3>
          <h3 className="text-red-400">{isLoadingPartial ? <Loader2 className="w-4 h-4 animate-spin" /> : tradeType === "BUY" ? (selectedMessage?.length > 0 && customerBidrate > 0) ? Number(customerBidrate) : Number(bidRate) : (selectedMessage?.length > 0 && customerAskrate > 0) ? Number(customerAskrate) : Number(askRate)}</h3>

        </Button>
    },
    {
      value: "modify",
      label: "Modify",
      content: ({ inputRef }: any) => (
        <>
          <div className="mb-4 space-y-1">
            <Label htmlFor="takeProfit">Take Profit</Label>
            <Input ref={inputRef} value={takeProfit} onChange={(e) => setTakeProfit(e.target.value)} id="takeProfit" type="text" />
          </div>
          <div className="space-y-2">
            <Label htmlFor="stopLoss">Stop Loss</Label>
            <Input onKeyDown={(e) => {
              if (openEdit && (e.key === "Enter")) {
                handleModifyActiveTrade();
              }
            }} value={stopLoss} onChange={(e) => setStopLoss(e.target.value)} id="stopLoss" type="text" />
          </div>
        </>
      ),
      footerButton:
        <Button
          size="lg"
          variant="outline"
          onClick={handleModifyActiveTrade}
          className="w-full gap-1 border-yellow-400 hover:bg-yellow-400/20"
        >
          <h3>Confirm: </h3>
          <h3 className="text-red-400">{isLoadingPartial ? <Loader2 className="w-4 h-4 animate-spin" /> : tradeType === "BUY" ? Number(askRate).toFixed(2) : Number(bidRate).toFixed(2)}</h3>
        </Button>
    },
    // {
    //   value: "edit",
    //   label: "Edit",
    //   content: ({ inputRef }: any) => (
    //     <>
    //       <div className="mb-4 space-y-1">
    //         <Label htmlFor="tradeType">Trade Type</Label>
    //         <Select onValueChange={(value) => setEditTradeType(value)}>
    //           <SelectTrigger className="col-span-3">
    //             <SelectValue
    //               placeholder="Select trade type.."
    //               className="text-[#7F8EA3]"
    //             />
    //           </SelectTrigger>
    //           <SelectContent>
    //             <SelectGroup>
    //               <SelectItem value="buy">Buy</SelectItem>
    //               <SelectItem value="sell">Sell</SelectItem>
    //             </SelectGroup>
    //           </SelectContent>
    //         </Select>
    //       </div>
    //       <div className="space-y-2">
    //         <Label htmlFor="editTradeRate">Rate</Label>
    //         <Input value={editTradeRate} onChange={(e) => setEditTradeRate(e.target.value)} id="editTradeRate" type="text" />
    //       </div>
    //       <div className="space-y-2">
    //         <Label htmlFor="editTradeQuantity">Quantity</Label>
    //         <Input
    //           value={editTradeQuantity}
    //           onChange={(e) => setEditTradeQuantity(e.target.value)}
    //           id="editTradeQuantity"
    //           type="text"
    //         />
    //       </div>
    //       <div className="space-y-2">
    //         <Label htmlFor="reason">Reason</Label>
    //         <Textarea
    //           value={editTradeReason}
    //           onChange={(e) => setEditTradeReason(e.target.value)}
    //           id="editTradeReason"
    //           rows={3}
    //           placeholder="Enter trade reason"
    //         />
    //       </div>
    //     </>
    //   ),
    //   footerButton:
    //     <Button
    //       size="lg"
    //       variant="outline"
    //       // onClick={handleModifyActiveTrade}
    //       className="w-full gap-1 border-yellow-400 hover:bg-yellow-400/20"
    //     >
    //       <h3>Submit Request</h3>
    //     </Button>
    // },
  ];

  return (
    <div className="mt-4 border-b pb-4 text-sm">
      <div className="grid grid-cols-2 items-center">
        <h3 className="text-muted-foreground">Active Trades</h3>
        {
          !isScreenshotMode &&
          <Input onChange={(e) => handleSearchTrades(e.target.value)} name="search" placeholder="search.." className="h-8" />
        }
      </div>

      <div
        className={cn(
          "mt-4 grid rounded-md px-3",
          !isScreenshotMode && "border bg-gray-300/20", isScreenshotMode ? "grid-cols-5" : "grid-cols-6"
        )}
      >
        <h3 className="text-left">ID</h3>
        <h3 className="text-left">B/S</h3>
        <h3 className="text-center">QTY</h3>
        <h3 className="text-center">RATE</h3>
        <h3 className={`${isScreenshotMode ? "text-right" : "text-center"}`}>P&L(AED)</h3>
        {
          !isScreenshotMode &&
          <h3 className="text-right">ACT</h3>
        }
      </div>
      {isPending ? (
        <p className="my-4 text-center">Loading...</p>
      ) : activeData?.length > 0 ? (
        activeData?.map((trade) => (
          <div
            key={trade.id}
            className={`mt-4 grid ${isScreenshotMode ? "grid-cols-5" : "grid-cols-6"} items-center`}
          >
            <h3 className="text-center" title={trade.createdAt}>{trade.id}</h3>
            <div className="flex items-center justify-center gap-1">
              {!isScreenshotMode && (trade.b_s === "Buy" ? (
                <ArrowUpCircle className="size-3 text-green-400" />
              ) : (
                <ArrowDownCircle className="size-3 text-red-400" />
              ))}
              <h3>{trade.b_s}</h3>
            </div>
            <h3 className="text-center">{trade.qty}</h3>
            <h3 className="text-left">{trade.rate?.toFixed(2)}</h3>
            <h3 className={"text-right" + (trade.pnl > 0 ? " text-green-400" : " text-red-400")}>{trade.pnl?.toFixed(2)}</h3>
            <h3 className="flex justify-center">{isScreenshotMode ? "" : trade.act}</h3>
          </div>
        ))
      ) : (
        <p className="text-center my-4">No active trades found!</p>
      )}

      <DynamicDialog
        open={openEdit}
        close={closeEdit}
        label={<span className="flex gap-2">Edit Trade <p className="text-sm text-gray-400">#{selectedTradeId}</p></span>}
        description="Edit your trade then click confirm"
        content={<DynamicTabs tabs={tabs} />}
        handleSubmit={null}
      />
    </div>
  )
}

export default ActiveTrades
