import { useMutation } from "@tanstack/react-query";
import { modifyActiveTrade } from "./modifyActiveTrade";

interface MutationOptionProps {
  onSuccess?: (data: any) => void;
  onError?: (error: Error) => void;
}

export const useModifyActiveTrade = (options: MutationOptionProps = {}) => {
  return useMutation({
    mutationFn: modifyActiveTrade,
    onSuccess: options.onSuccess,
    onError: options.onError,
    retry: 3, // Retries 3 times on failure (default behavior)
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 20000),
  });
};