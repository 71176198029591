import { getToken } from "@/auth/config/keycloak"
import { useQuery } from "@tanstack/react-query"

export const GetSupportRoleByBusiness = (
    businessId: string,
    userId: string,
) => {
    const token = getToken()
    const { data, isPending, error, refetch } = useQuery({
        queryKey: ["getSupportRoleByBusiness", businessId, userId],
        queryFn: async () => {
            const url = `${process.env.NEXT_PUBLIC_API_SUPPORT_URL}/support/manager/role/${businessId}/${userId}`
            const res = await fetch(url, {
                cache: "no-store",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                }
            })
            if (!res.ok) {
                throw new Error("Failed to fetch integrations")
            }

            const data = await res.json()
            return data
        },
        enabled: !!businessId && !!userId,
    })

    return {
        data,
        isPending,
        error,
        refetch,
    }
}