"use client"

import React, { useState } from "react"
// Import Roboto font files
import robotoLight from "@fontsource/roboto/files/roboto-latin-300-normal.woff"
import robotoRegular from "@fontsource/roboto/files/roboto-latin-400-normal.woff"
import robotoBold from "@fontsource/roboto/files/roboto-latin-700-normal.woff"
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer"

import { Button } from "@/components/ui/button"

// Register font
Font.register({
  family: "Roboto",
  fonts: [
    { src: robotoLight, fontWeight: 300 },
    { src: robotoRegular, fontWeight: 400 },
    { src: robotoBold, fontWeight: 700 },
  ],
})

// Define the styles for the PDF document
const styles = StyleSheet.create({
  page: {
    paddingTop: 40,
    paddingBottom: 60,
    paddingHorizontal: 20,
    fontFamily: "Roboto",
    fontSize: 10,
  },
  watermark: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 1,
    transform: 'rotate(-30deg)',
    zIndex: -1,
  },
  headerSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 40,
  },
  header: {
    fontSize: 13,
    fontWeight: 800,
  },
  subHeader: {
    fontSize: 10,
    color: "#000",
  },
  marginLimit: {
    fontSize: 10,
    textAlign: "center",
    fontWeight: 800,
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#A1A1AB",
    fontWeight: 800,
    fontSize: 9,
    color: "#000",
    alignItems: "center",
  },
  cell: {
    padding: 3,
    color: "#000",
    textAlign: "center",
    flex: 1,
    fontSize: 10,
    fontWeight: 900,
    borderRightWidth: 1,
    borderRightColor: "#E8E7E4",
    height: "100%",
  },
  cell3: {
    padding: 3,
    color: "#000",
    textAlign: "center",
    flex: 1,
    fontSize: 10,
    height: "100%",
  },
  leftAlignedCell: {
    padding: 3,
    color: "#000",
    textAlign: "center",
    flex: 1,
    fontSize: 10,
    fontWeight: 900,
    borderRightWidth: 1,
    borderRightColor: "#E8E7E4",
    height: "100%",
  },
  cell1: {
    padding: 3,
    color: "#000",
    textAlign: "center",
    flex: 1,
    fontSize: 10,
    borderRightWidth: 1,
    borderRightColor: "#E4E4E4",
    height: "100%",
  },
  cellWithoutBorder: {
    padding: 3,
    textAlign: "center",
    flex: 1,
  },
  leftAlignedCell1: {
    padding: 3,
    color: "#000",
    textAlign: "center",
    flex: 1,
    fontSize: 10,
    borderRightWidth: 1,
    borderRightColor: "#E4E4E4",
    height: "100%",
  },
  summaryTable: {
    borderWidth: 1,
    borderColor: "#E4E4E4",
    marginBottom: 15,
  },
  summaryRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#E4E4E4",
    alignItems: "center",
  },
  sectionTitle: {
    fontWeight: 800,
    // marginTop: 15,
    // marginBottom: 5,
    fontSize: 10,
    textDecoration: "underline",
  },
  tableFooter: {
    fontWeight: 800,
    flexDirection: "row",
    paddingVertical: 5,
    backgroundColor: "#fff",
  },
  footerText: {
    fontSize: 8,
    color: "#000",
    textAlign: "center",
    bottom: 0,
  },
})

const dubaiTimeZone = "Asia/Dubai"

export function formatCurrentDateTime() {
  const now = new Date()

  // Format date components in Dubai time
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "long",
    year: "numeric",
    timeZone: dubaiTimeZone,
  }).format(now)

  // Format time components in Dubai time
  const formattedTime = new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: dubaiTimeZone,
  }).format(now)

  return {
    currentDate: formattedDate,
    currentTime: formattedTime,
  }
}

function formatCustomDateTime(dateString) {
  const date = new Date(dateString)

  // Format date components in Dubai time
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "long",
    year: "numeric",
    timeZone: dubaiTimeZone,
  }).format(date)

  // Format time components in Dubai time, including seconds
  const formattedTime = new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: dubaiTimeZone,
  }).format(date)

  return {
    currentDate: formattedDate,
    currentTime: formattedTime,
  }
}

function formatNumber(num) {
  return num?.toFixed(2) ?? 0
}

export const showComma = (value) => {
  if (isNaN(value)) {
    console.log("NaN detected!!! Please provide a valid number!")
    return
  }
  return new Intl.NumberFormat("en-US").format(Number(value))
}
// Define the PDF document structure
export const MyDocument = ({ data }) => {
  const dateTime = formatCurrentDateTime()
  const onzToGram = 31.113484
  const ttbToGram = 116.52
  const quantityToOnz = 0.037462
  const totalQuantityToOnz = 3.746667
  const activeNetQty =
    data?.activeTradeSummary?.buyWeight / ttbToGram -
    data?.activeTradeSummary?.sellWeight / ttbToGram;

  const todayDate = formatCustomDateTime(new Date()).currentDate;

  const filteredCompletedTradeArray = data?.completedTradeArray
  ?.filter(item => formatCustomDateTime(item?.account?.createdAt).currentDate === todayDate);

  function calculateSummary(trades) {
    let netProfit = 0;
    let buyWeight = 0;
    let sellWeight = 0;
    trades?.forEach((trade) => {
      const profit = trade.account.type === 'profit' ? trade.account.amount : -trade.account.amount;
      netProfit += profit;

      if (trade.trade_type === 'BUY') {
        buyWeight += trade.quantity * trade.base_metal_weight;
      } else if (trade.trade_type === 'SELL') {
        sellWeight += trade.quantity * trade.base_metal_weight;
      }
    });

    return {
      netProfit: netProfit,
      buyWeight: buyWeight,
      sellWeight: sellWeight
    };
  }

  const completedNetQty =
    calculateSummary(filteredCompletedTradeArray)?.buyWeight / ttbToGram -
    calculateSummary(filteredCompletedTradeArray)?.sellWeight / ttbToGram

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        {/* Watermark */}
        {data?.vendor?.watermark?.path && (
          <img
            src={data.vendor.watermark.path}
            style={styles.watermark}
            fixed
          />
        )}
        {/* Header Section */}
        <View style={styles.headerSection} fixed>
          <View>
            <Text style={styles.header}>
              {data?.summary?.name} ({data?.vendor?.init_ID}{" "}
              {data?.summary?.uid})
            </Text>
            <Text style={styles.subHeader}>Statement from AllTime</Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: 10, textAlign: "center" }}>
              {dateTime.currentDate}
            </Text>
            <Text style={{ fontSize: 10, textAlign: "center" }}>
              {dateTime.currentTime}
            </Text>
          </View>
        </View>
        {/* Balance Summary Table */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 15,
          }}
        >
          <Text style={styles.marginLimit}>
            Market Price:{" "}
            {data?.goldPrice?.askPrice > 0
              ? formatNumber(data?.goldPrice?.askPrice)
              : "0.00"}
          </Text>
          <Text style={styles.marginLimit}>
            Margin Limit: {data?.summary?.marginLimit} (
            {data?.summary?.marginLimit > 0 ? "Sell" : "Buy"})
          </Text>
        </View>

        <View style={styles.summaryTable}>
          <View style={styles.tableHeader}>
            <Text style={styles.leftAlignedCell}>Balance</Text>
            <Text style={styles.cell}>Deposit</Text>
            <Text style={styles.cell}>Withdraw</Text>
            <Text style={styles.cell}>Profit - Loss</Text>
            <Text style={styles.cell}>Gold (Onz)</Text>
            <Text style={styles.cell}>Gold (TTB)</Text>
            <Text style={styles.cell}>Total ($)</Text>
            <Text style={styles.cell}>Current Value ($)</Text>
            <Text style={styles.cell}>P&L ($)</Text>
            <Text style={styles.cell}>P&L (AED)</Text>
            <Text style={styles.cell}>Net Balance (AED)</Text>
            <Text style={[styles.cell, { borderRightWidth: 0 }]}>
              Withdrawable
            </Text>
          </View>
          <View style={styles.summaryRow}>
            <Text style={styles.leftAlignedCell1}>
              {showComma(formatNumber(data?.summary?.balance))}
            </Text>
            <Text style={styles.cell1}>
              {showComma(formatNumber(data?.transactionSummary?.total_deposit))}
            </Text>
            <Text style={styles.cell1}>
              {showComma(
                formatNumber(data?.transactionSummary?.total_withdraw)
              )}
            </Text>
            <Text style={styles.cell1}>
              {showComma(formatNumber(data?.summary?.netProfitLoss))}
            </Text>
            <Text style={styles.cell1}>
              {formatNumber(
                (data?.summary?.buyWeight - data?.summary?.sellWeight) /
                onzToGram
              )}
            </Text>
            <Text style={styles.cell1}>
              {(
                (data?.summary?.buyWeight - data?.summary?.sellWeight) /
                ttbToGram
              )?.toFixed(2)}
            </Text>
            <Text style={styles.cell1}>
              {showComma(formatNumber(data?.summary?.netOpenPrice))}
            </Text>
            <Text style={styles.cell1}>
              {showComma(formatNumber(data?.summary?.currentValueUSD))}
            </Text>
            <Text style={styles.cell1}>
              {showComma(formatNumber(data?.summary?.currentProfitLossUSD))}
            </Text>
            <Text style={styles.cell1}>
              {showComma(formatNumber(data?.summary?.currentProfitLossAED))}
            </Text>
            <Text style={styles.cell1}>
              {showComma(formatNumber(data?.summary?.netBalance))}
            </Text>
            <Text style={[styles.cell1, { borderRightWidth: 0 }]}>
              {showComma(formatNumber(data?.summary?.withdrawableBalance))}
            </Text>
          </View>
        </View>

        {/* Active Positions Table */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            backgroundColor: "#F3F3F3",
            padding: 5,
            marginTop: 13,
          }}
        >
          <Text style={styles.sectionTitle}>Active Positions</Text>
          <Text style={styles.cell3}>
            Buy QTY:{" "}
            {(data?.activeTradeSummary?.buyWeight / ttbToGram)?.toFixed(2)}
          </Text>
          <Text style={styles.cell3}>
            Sell QTY:{" "}
            {(data?.activeTradeSummary?.sellWeight / ttbToGram)?.toFixed(2)}
          </Text>
          <Text style={styles.cell3}>
            Net QTY: {activeNetQty.toFixed(2)} (
            {activeNetQty > 0 ? "Buy" : "Sell"} Position)
          </Text>
          <Text style={styles.cell3}>
            [P&L-AED:{" "}
            {showComma(data?.activeTradeSummary?.profitLossAED?.toFixed(2))}]
          </Text>
        </View>

        <View style={styles.summaryTable}>
          <View style={styles.tableHeader}>
            <Text style={styles.leftAlignedCell}>S/No</Text>
            <Text style={styles.cell}>Date - Time</Text>
            <Text style={styles.cell}>Item</Text>
            <Text style={styles.cell}>PCS</Text>
            <Text style={styles.cell}>B/S</Text>
            <Text style={styles.cell}>QTY Onz</Text>
            <Text style={styles.cell}>Open Rate</Text>
            <Text style={styles.cell}>Total Onz</Text>
            <Text style={[styles.cell, { borderRightWidth: 0 }]}>P&L-AED</Text>
          </View>
          {data?.activeTradeArray?.length > 0 &&
            data?.activeTradeArray?.map((item, index) => (
              <View style={styles.summaryRow}>
                <Text style={styles.leftAlignedCell1}>{index + 1}</Text>
                <Text style={styles.cell1}>
                  {formatCustomDateTime(item?.createdAt).currentDate}{" "}
                  {formatCustomDateTime(item?.createdAt).currentTime}
                </Text>
                <Text style={styles.cell1}>{item?.metal_type}</Text>
                <Text style={styles.cell1}>{item?.quantity}</Text>
                <Text style={styles.cell1}>{item?.trade_type}</Text>
                <Text style={styles.cell1}>
                  {(item?.quantity * quantityToOnz)?.toFixed(7)}
                </Text>
                <Text style={styles.cell1}>
                  {item?.executed_trade_open_rate?.toFixed(2)}
                </Text>
                <Text style={styles.cell1}>
                  {formatNumber(item?.quantity * totalQuantityToOnz)}
                </Text>
                <Text style={[styles.cell1, { borderRightWidth: 0 }]}>
                  {showComma(item?.profitLossAED?.toFixed(2))}
                </Text>
              </View>
            ))}

          <View style={styles.tableFooter}>
            <Text style={styles.cellWithoutBorder}></Text>
            <Text style={styles.cellWithoutBorder}></Text>
            <Text style={styles.cellWithoutBorder}></Text>
            <Text style={styles.cellWithoutBorder}></Text>
            <Text style={styles.cellWithoutBorder}></Text>
            <Text style={styles.cellWithoutBorder}></Text>
            <Text
              style={[
                styles.cell,
                { fontSize: 12, fontWeight: 800, borderRight: "none" },
              ]}
            >
              Subtotal
            </Text>
            <Text
              style={[
                styles.cell,
                { fontSize: 12, fontWeight: 800, borderRight: "none" },
              ]}
            >
              {formatNumber(
                data?.activeTradeArray?.reduce(
                  (accumulator, current) =>
                    accumulator +
                    (current?.trade_type === "BUY" ? 1 : -1) *
                    (current?.quantity * totalQuantityToOnz).toFixed(2),
                  0
                )
              )}
            </Text>
            <Text
              style={[
                styles.cell,
                {
                  fontSize: 12,
                  fontWeight: 800,
                  borderRightWidth: 0,
                  borderRight: "none",
                },
              ]}
            >
              {showComma(
                formatNumber(
                  data?.activeTradeArray?.reduce(
                    (accumulator, current) =>
                      accumulator + current?.profitLossAED,
                    0
                  )
                )
              )}
            </Text>
          </View>
        </View>

        {/* Pending Positions Table */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            backgroundColor: "#F3F3F3",
            padding: 5,
            marginTop: 13,
          }}
        >
          <Text style={styles.sectionTitle}>Pending Positions</Text>
        </View>

        <View style={styles.summaryTable}>
          <View style={styles.tableHeader}>
            <Text style={styles.leftAlignedCell}>S/No</Text>
            <Text style={styles.cell}>Date - Time</Text>
            <Text style={styles.cell}>Item</Text>
            <Text style={styles.cell}>QTY</Text>
            <Text style={styles.cell}>B/S</Text>
            <Text style={styles.cell}>QTY Onz</Text>
            <Text style={styles.cell}>Trigger Rate($)</Text>
            <Text style={styles.cell}>Trigger Gap($)</Text>
          </View>
          {data?.pendingTradeArray?.length > 0 &&
            data?.pendingTradeArray?.map((item, index) => (
              <View style={styles.summaryRow}>
                <Text style={styles.leftAlignedCell1}>{index + 1}</Text>
                <Text style={styles.cell1}>
                  {formatCustomDateTime(item?.pendingAt).currentDate}{" "}
                  {formatCustomDateTime(item?.pendingAt).currentTime}
                </Text>
                <Text style={styles.cell1}>{item?.metal_type}</Text>
                <Text style={styles.cell1}>{item?.quantity}</Text>
                <Text style={styles.cell1}>{item?.trade_type}</Text>
                <Text style={styles.cell1}>
                  {(item?.quantity * quantityToOnz)?.toFixed(7)}
                </Text>
                <Text style={styles.cell1}>
                  {item?.pending_trade_trigger_rate?.toFixed(2)}
                </Text>
                <Text style={styles.cell1}>
                  {formatNumber(data?.goldPrice?.askPrice - item?.pending_trade_trigger_rate)}
                </Text>
              </View>
            ))}
        </View>

        {/* Completed Positions Table */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            backgroundColor: "#F3F3F3",
            padding: 5,
            marginTop: 13,
          }}
        >
          <Text style={styles.sectionTitle}>Todays Completed Positions</Text>
          <Text style={styles.cell3}>
            Buy QTY:{" "}
            {(calculateSummary(filteredCompletedTradeArray)?.buyWeight / ttbToGram)?.toFixed(2)}
          </Text>
          <Text style={styles.cell3}>
            Sell QTY:{" "}
            {(calculateSummary(filteredCompletedTradeArray)?.sellWeight / ttbToGram)?.toFixed(2)}
          </Text>
          <Text style={styles.cell3}>
            Net QTY: {completedNetQty?.toFixed(2)} (
            {completedNetQty > 0 ? "Buy" : "Sell"} Position)
          </Text>
          <Text style={styles.cell3}>
            [Closed P&L-AED:{" "}
            {showComma(calculateSummary(filteredCompletedTradeArray)?.netProfit?.toFixed(2))}]
          </Text>
        </View>

        <View style={styles.summaryTable}>
          <View style={styles.tableHeader}>
            <Text style={styles.leftAlignedCell}>S/No</Text>
            <Text style={styles.cell}>Item</Text>
            <Text style={styles.cell}>Date - Time</Text>
            <Text style={styles.cell}>QTY</Text>
            <Text style={styles.cell}>B/S</Text>
            <Text style={styles.cell}>Open Rate</Text>
            <Text style={styles.cell}>Date - Time</Text>
            <Text style={styles.cell}>B/S</Text>
            <Text style={styles.cell}>Close Rate</Text>
            <Text style={[styles.cell, { borderRightWidth: 0 }]}>P&L-AED</Text>
          </View>
          {data?.completedTradeArray?.length > 0 &&
            filteredCompletedTradeArray
              .map((item, index) => (
                <View style={styles.summaryRow}>
                  <Text style={styles.leftAlignedCell1}>{index + 1}</Text>
                  <Text style={styles.cell1}>{item?.metal_type}</Text>
                  <Text style={styles.cell1}>
                    {formatCustomDateTime(item?.createdAt).currentDate}{" "}
                    {formatCustomDateTime(item?.createdAt).currentTime}
                  </Text>
                  <Text style={styles.cell1}>{item?.quantity}</Text>
                  <Text style={styles.cell1}>{item?.trade_type}</Text>
                  <Text style={styles.cell1}>
                    {item?.executed_trade_open_rate?.toFixed(2)}
                  </Text>
                  <Text style={styles.cell1}>
                    {formatCustomDateTime(item?.account?.createdAt).currentDate}{" "}
                    {formatCustomDateTime(item?.account?.createdAt).currentTime}
                  </Text>
                  <Text style={styles.cell1}>
                    {item?.trade_type === "BUY" ? "SELL" : "BUY"}
                  </Text>
                  <Text style={styles.cell1}>
                    {item?.executed_trade_close_rate?.toFixed(2)}
                  </Text>
                  <Text style={[styles.cell1, { borderRightWidth: 0 }]}>
                    {showComma(
                      (
                        (item?.account?.type === "loss" ? -1 : 1) *
                        item?.account?.amount
                      )?.toFixed(2)
                    )}
                  </Text>
                </View>
              ))}
          <View style={styles.tableFooter}>
            <Text style={styles.cellWithoutBorder}></Text>
            <Text style={styles.cellWithoutBorder}></Text>
            <Text style={styles.cellWithoutBorder}></Text>
            <Text style={styles.cellWithoutBorder}></Text>
            <Text style={styles.cellWithoutBorder}></Text>
            <Text style={styles.cellWithoutBorder}></Text>
            <Text style={styles.cellWithoutBorder}></Text>
            <Text style={styles.cellWithoutBorder}></Text>
            <Text
              style={[
                styles.cell,
                { fontSize: 12, fontWeight: 800, borderRight: "none" },
              ]}
            >
              Subtotal
            </Text>
            <Text
              style={[
                styles.cell,
                {
                  fontSize: 12,
                  fontWeight: 800,
                  borderRightWidth: 0,
                  borderRight: "none",
                },
              ]}
            >
              {showComma(
                formatNumber(
                  filteredCompletedTradeArray?.reduce(
                    (accumulator, current) =>
                      accumulator +
                      (current?.account?.type === "loss" ? -1 : 1) *
                      current?.account?.amount,
                    0
                  )
                )
              )}
            </Text>
          </View>
        </View>

        {/* Footer Note */}
        <View
          style={{
            fontSize: 8,
            position: "absolute",
            bottom: 10,
            left: 0,
            right: 0,
          }}
          fixed
        >
          <Text style={{ textAlign: "center" }}>
            Profit / loss for the outstanding position is calculated based on
            the market price at time of this report generation. Margin Limit
            varies depend upon the financial transactions and other buy or sell
            of positions in the account.
          </Text>
          <Text style={{ marginTop: 5, textAlign: "center" }}>
            @2023 Dream Emirates Trading LLC
          </Text>
        </View>
      </Page>
    </Document>
  )
}

// PDF Download Button component
const ActivePDFGenerator = ({
  statements,
  statementRefetch,
  handleMessage,
}) => {
  const [loading, setLoading] = useState(false)

  const handleGenerateAndSendPDF = async () => {
    // debugger
    setLoading(true)
    statementRefetch()

    try {
      // Generate the PDF blob
      const blob = await pdf(<MyDocument data={statements} />).toBlob()
      const generatedFile = new File(
        [blob],
        `${statements?.summary?.name}_statement_${formatCurrentDateTime().currentDate
        }_${formatCurrentDateTime().currentTime}.pdf`,
        { type: "application/pdf" }
      )
      // Pass the blob to the SetMessage function
      handleMessage(generatedFile)
    } catch (error) {
      console.error("Error generating PDF:", error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      {loading ? (
        <Button
          disabled
          size="lg"
          variant="outline"
          className="mt-4 w-full py-4"
        >
          Generating & Sending...
        </Button>
      ) : (
        <Button
          size="lg"
          variant="outline"
          className="mt-4 w-full py-4"
          onClick={handleGenerateAndSendPDF}
        >
          Send Active Positions
        </Button>
      )}
    </div>
  )
}

export default ActivePDFGenerator
