"use client"

import React, { ReactNode, useEffect, useState } from "react"
import { useDeletePendingTrades } from "@/api_store/mutations/pendingTrades/useDeletePendingTrades"
import { useModifyPendingTrades } from "@/api_store/mutations/pendingTrades/useModifyPendingTrades"
import { GetPendingTrades } from "@/api_store/queries/pendingTrades/getPendingTrades"
import {
  ArrowDownCircle,
  ArrowUpCircle,
  FileEditIcon,
  Loader2,
  Trash2Icon,
} from "lucide-react"

import DynamicDialog from "../custom/dynamic-dialog"
import DynamicTabs from "../custom/dynamic-tabs"
import { Button } from "../ui/button"
import { Input } from "../ui/input"
import { Label } from "../ui/label"
import { ToastAction } from "../ui/toast"
import { useToast } from "../ui/use-toast"
import { useKeycloak } from "@/auth/provider/KeycloakProvider"
import { cn, formatCustomDateTime } from "@/lib/utils"

type Props = {
  userId: number
  vendorId: number
  refetchQuery: (refetch: () => Promise<any>) => void
  onSendMessage: (message: any) => void;
  conversationId: any
  isScreenshotMode: boolean
}

interface TradesType {
  id: number
  b_s: "Buy" | "Sell"
  qty: number
  rate: number
  act: ReactNode
  createdAt: string
}

interface ISelectedTrade {
  id: number | null
  livePrice: number
  liveAskPrice: number
  liveBidPrice: number
  pendingTradeRate: number
  takeProfit: number
  stopLoss: number
  metalType: string
  tradeType: string
  baseMetalWeight: number
  quantity: number
  cancelSltp: boolean
}

const PendingTrades = ({ userId, vendorId, refetchQuery, onSendMessage, conversationId, isScreenshotMode }: Props) => {
  const [openEdit, setOpenEdit] = useState(false)
  const [pendingTrades, setPendingTrades] = useState<TradesType[]>([])
  const [fullData, setFullData] = useState<TradesType[]>([])
  const [selectedTradeId, setSelectedTradeId] = useState<number | null>(null)
  const [selectedTrade, setSelectedTrade] = useState<ISelectedTrade>({
    id: null,
    livePrice: 2500,
    liveAskPrice: 2600,
    liveBidPrice: 2550,
    pendingTradeRate: 0,
    takeProfit: 0,
    stopLoss: 0,
    metalType: "",
    tradeType: "",
    baseMetalWeight: 0,
    quantity: 0,
    cancelSltp: false,
  })

  const { tradeToken: token } = useKeycloak();
  const { toast } = useToast()

  const { data, isPending, error, refetch } = GetPendingTrades({
    userId,
    vendorId,
    token,
    conversationId
  })
  refetchQuery(refetch)

  // API call here
  const { mutate, isPending: isLoadingPending } = useDeletePendingTrades({
    onSuccess: (data) => {
      if (data?.status) {
        const message = {
          id: conversationId,
          message: `Your pending trade #${selectedTradeId} has been deleted successfully!\n\nTrade Type: ${data?.data?.tradeType}\nTrigger Rate: $${data?.data?.triggerRate}\nQuantity: ${data?.data?.quantity} ${data?.data?.metalType}`,
          attachments: [],  // If needed, you can add attachments here
          attachImages: [],  // If needed, you can add image attachments here
          conversationId: conversationId
        };
        onSendMessage(message);

        toast({
          variant: "default",
          className: "bg-green-600 text-white hover:bg-green-700",
          description: "Pending trade deleted successfully!",
        })
        refetch()
        closeEdit()
      }
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        description: "Server error. Please contact support!",
      })
      console.log(error)
    },
  })

  const handleDelete = () => {
    mutate({
      vendorId,
      id: selectedTradeId,
      token,
    })
  }

  const { mutate: modify, isPending: isModifyLoadingPending } =
    useModifyPendingTrades({
      onSuccess: (data) => {
        if (data?.status) {
          const message = {
            id: conversationId,
            message: `Your pending trade #${data?.data?.tradeId} has been modified successfully!\n\nTrade Type: ${data?.data?.tradeType}\nTrigger Rate: $${data?.data?.pendingTradeRate}\nTake Profit: $${data?.data?.takeProfit === null ? 0 : data?.data?.takeProfit}\nStop Loss: $${data?.data?.stopLoss === null ? 0 : data?.data?.stopLoss}`, //\nQuantity: ${data?.data?.quantity} ${data?.data?.metalType}
            attachments: [],  // If needed, you can add attachments here
            attachImages: [],  // If needed, you can add image attachments here
            conversationId: conversationId
          };
          onSendMessage(message);
          toast({
            variant: "default",
            className: "bg-green-600 text-white hover:bg-green-700",
            description: "Pending trade modified successfully!",
          })
          refetch()
          closeEdit()
        }
      },
      onError: (error) => {
        toast({
          variant: "destructive",
          description: "Server error. Please contact support!",
        })
        console.log(error)
      },
    })

  const handleRateChange = (e: any) => {
    const newRate = e.target.value
    setSelectedTrade((prevTrade) => ({
      ...prevTrade,
      pendingTradeRate: newRate,
    }))
  }

  const handleTakeProfitChange = (e: any) => {
    const newTakeProfit = e.target.value
    setSelectedTrade((prevTrade) => ({
      ...prevTrade,
      takeProfit: newTakeProfit,
    }))
  }

  const handleStopLossChange = (e: any) => {
    const newStopLoss = e.target.value
    setSelectedTrade((prevTrade) => ({
      ...prevTrade,
      stopLoss: newStopLoss,
    }))
  }

  const handleEditModify = () => {
    if (selectedTrade.pendingTradeRate <= 0) {
      toast({
        variant: "destructive",
        description: "Please enter a valid rate!",
      })
      return
    }
    modify({
      ...selectedTrade,
      vendorId,
      token,
    })
  }

  const handleEditsltp = () => {
    if (selectedTrade.takeProfit == 0 || selectedTrade.stopLoss == 0) {
      toast({
        variant: "destructive",
        description: "Stop Loss and Take Profit can't be 0!",
      })
      return
    }
    modify({
      ...selectedTrade,
      vendorId,
      token,
    })
  }

  useEffect(() => {
    if (data) {
      const dt = data?.data

      const formatData = dt?.map((item: any) => {
        const crtDate = formatCustomDateTime(item?.pendingAt)

        return ({
          id: item.id,
          b_s: item.trade_type === "BUY" ? "Buy" : "Sell",
          qty: item.quantity,
          createdAt: crtDate?.currentDate + " " + crtDate?.currentTime,
          rate:
            item?.pending_trade_trigger_rate === null
              ? 0
              : item?.pending_trade_trigger_rate,
          act: (
            <FileEditIcon
              onClick={() => {
                setOpenEdit(true)
                setSelectedTradeId(item.id)
                setSelectedTrade({
                  id: item.id,
                  livePrice: 2500,
                  liveAskPrice: 2600,
                  liveBidPrice: 2550,
                  pendingTradeRate: item.pending_trade_trigger_rate,
                  takeProfit: item.take_profit,
                  stopLoss: item.stop_loss,
                  metalType: item.metal_type,
                  tradeType: item.trade_type,
                  baseMetalWeight: item.base_metal_weight,
                  quantity: item.quantity,
                  cancelSltp: false,
                })
              }}
              className="size-4 cursor-pointer"
            />
          ),
        })
      })

      setPendingTrades(formatData)
      setFullData(formatData)
    }
  }, [data, conversationId])

  const closeEdit = () => {
    setOpenEdit(false)
  }

  const tabs = [
    {
      value: "delete",
      label: "Delete",
      content: (
        <h2 className="text-lg">Are you sure you want to delete this trade?</h2>
      ),
      footerButton: ({ inputRef }: any) => (
        <Button
          size="lg"
          variant="outline"
          ref={inputRef}
          className="w-full border-red-400 hover:bg-red-400/20"
          onClick={handleDelete}
        >
          <h3>
            {isLoadingPending ? (
              <Loader2 className="w-4 h-4 animate-spin" />
            ) : (
              <>Confirm</>
            )}
          </h3>
        </Button>
      ),
    },
    {
      value: "modify",
      label: "Modify",
      content: ({ inputRef }: any) => (
        <div>
          <div className="grid grid-cols-[20%,80%] items-center">
            <h3>Rate: </h3>
            <Input
              id="rate"
              type="text"
              value={selectedTrade?.pendingTradeRate}
              onChange={handleRateChange}
              ref={inputRef}
              onKeyDown={(e) => {
                if (openEdit && (e.key === "Enter")) {
                  handleEditModify();
                }
              }}
              defaultValue={selectedTrade?.pendingTradeRate}
              placeholder="Enter Rate"
            />
          </div>
        </div>
      ),
      footerButton: (
        <Button
          size="lg"
          variant="outline"
          className="w-full border-green-400 hover:bg-green-400/20"
          onClick={handleEditModify}
        >
          {isModifyLoadingPending ? (
            <Loader2 className="w-4 h-4 animate-spin" />
          ) : (
            <>Confirm</>
          )}
        </Button>
      ),
    },
    {
      value: "sltp",
      label: "SL / TP",
      content: ({ inputRef }: any) => (
        <>
          <div className="mb-4 space-y-1">
            <Label htmlFor="takeProfit">Take Profit</Label>
            <Input
              id="takeProfit"
              type="text"
              value={selectedTrade?.takeProfit}
              onChange={handleTakeProfitChange}
              ref={inputRef}
            />
          </div>
          <div className="space-y-1">
            <Label htmlFor="stopLoss">Stop Loss</Label>
            <Input
              id="stopLoss"
              type="text"
              value={selectedTrade?.stopLoss}
              onChange={handleStopLossChange}
              onKeyDown={(e) => {
                if (openEdit && (e.key === "Enter")) {
                  handleEditsltp();
                }
              }}
            />
          </div>
        </>
      ),
      footerButton: (
        <Button
          size="lg"
          variant="outline"
          onClick={handleEditsltp}
          className="w-full gap-1 border-yellow-400 hover:bg-yellow-400/20"
        >
          {isModifyLoadingPending ? (
            <Loader2 className="w-4 h-4 animate-spin" />
          ) : (
            <>Confirm</>
          )}
        </Button>
      ),
    },
  ]

  const handleSearchTrades = (value: string) => {
    const dt = [...fullData]
    if (value === "") {
      setPendingTrades(dt)
      return
    }
    const data = dt?.filter(
      (item: any) =>
        item?.id?.toString().includes(value) ||
        item?.b_s?.toString().toLowerCase().includes(value) ||
        item?.qty?.toString().includes(value) ||
        item?.rate?.toString().includes(value)
    )
    setPendingTrades(data)
  }

  return (
    <div className="mt-4 border-b pb-4 text-sm">
      <div className="grid grid-cols-2 items-center">
        <h3 className="text-muted-foreground">Pending Trades</h3>
        {
          !isScreenshotMode &&
          <Input
            onChange={(e) => handleSearchTrades(e.target.value)}
            name="search"
            placeholder="search.."
            className="h-8"
          />
        }
      </div>
      <div
        className={cn("mt-4 grid gap-2 rounded-md  px-2 font-medium", !isScreenshotMode && "border bg-gray-300/20", isScreenshotMode ? "grid-cols-4" : "grid-cols-5")}
      >
        <h3>ID</h3>
        <h3>B/S</h3>
        <h3>QTY</h3>
        <h3>RATE</h3>
        {
          !isScreenshotMode &&
          <h3>ACT</h3>
        }
      </div>
      {isPending ? (
        <p className="text-center my-4">Loading...</p>
      ) : pendingTrades?.length > 0 ? (
        pendingTrades?.map((trade, index) => {
          return (
            <div title={trade.createdAt} key={trade.id} className={`mt-4 grid ${isScreenshotMode ? "grid-cols-4" : "grid-cols-5"} gap-2 px-2`}>
              <h3>{trade.id}</h3>
              <div className="flex items-center gap-1">
                {!isScreenshotMode && (trade.b_s == "Buy" ? (
                  <ArrowUpCircle className="size-3 text-green-400" />
                ) : (
                  <ArrowDownCircle className="size-3 text-red-400" />
                ))}
                <h3>{trade.b_s}</h3>
              </div>
              <h3>{trade.qty}</h3>
              <h3>{trade.rate.toFixed(2)}</h3>
              {!isScreenshotMode && <h3>{trade.act}</h3>}
            </div>
          )
        })
      ) : (
        <p className="text-center my-4">No pending trades found!</p>
      )}

      <DynamicDialog
        open={openEdit}
        close={closeEdit}
        label={
          <span className="flex gap-2">
            Edit Pending Trade{" "}
            <p className="text-sm text-gray-400">#{selectedTradeId}</p>
          </span>
        }
        description="Edit your pending trade then click confirm"
        content={<DynamicTabs tabs={tabs} />}
        handleSubmit={null}
      />
    </div>
  )
}

export default PendingTrades
