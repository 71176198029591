import { getToken } from "@/auth/config/keycloak"
import { useMutation } from "@tanstack/react-query"

interface IModifyPendingTrades {
  id: number | null
  livePrice: number
  liveAskPrice: number
  liveBidPrice: number
  pendingTradeRate: number
  takeProfit: number
  stopLoss: number
  metalType: string
  tradeType: string
  baseMetalWeight: number
  quantity: number
  cancelSltp: boolean
  vendorId: number
  token: string
}

interface MutationOptionProps {
  onSuccess?: (data: any) => void
  onError?: (error: Error) => void
}

const modifyPendingTrades = async ({
  id,
  livePrice,
  liveAskPrice,
  liveBidPrice,
  pendingTradeRate,
  takeProfit,
  stopLoss,
  metalType,
  tradeType,
  baseMetalWeight,
  quantity,
  cancelSltp,
  vendorId,
  token,
}: IModifyPendingTrades) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_STAGING}/opportunity/virtual-trading/modify-pending-trade`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "X-VendorId": vendorId.toString(),
      },
      body: JSON.stringify({
        id: id,
        livePrice: livePrice,
        liveAskPrice: liveAskPrice,
        liveBidPrice: liveBidPrice,
        pendingTradeRate: pendingTradeRate,
        takeProfit: takeProfit,
        stopLoss: stopLoss,
        metalType: metalType,
        tradeType: tradeType,
        baseMetalWeight: baseMetalWeight,
        quantity: quantity,
        cancelSltp: cancelSltp,
      }),
    }
  )

  if (!response.ok) {
    throw { status: response.status, response: response.statusText }
  }

  const data = await response.json()
  return data
}

export const useModifyPendingTrades = (options: MutationOptionProps = {}) => {
  return useMutation({
    mutationFn: modifyPendingTrades,
    onSuccess: options.onSuccess,
    onError: options.onError,
    retry: 3,
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000),
  })
}
