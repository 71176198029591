import { useQuery } from "@tanstack/react-query"

export const getVendorByPhone = (phone: any, token: string) => {
  const { data, isPending, error, refetch } = useQuery({
    queryKey: ["getVendorInfo", phone],
    queryFn: async () => {
      const url = `${process.env.NEXT_PUBLIC_API_STAGING}/vendor/external/vendor-by-phone?phone=${phone}`
      const res = await fetch(url, {
        cache: "no-store",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      if (!res.ok) {
        throw new Error("Failed to fetch user info!")
      }

      const data = await res.json()
      return data
    },
    // enabled: !!phone,
  })

  return {
    data,
    isPending,
    error,
    refetch,
  }
}
