interface UserMutationProps {
  vendorId: number
  userId: number
  metalType: string
  tradeType: "BUY" | "SELL"
  baseMetalWeight: number
  livePrice: number
  quantity: number
  token: string
}

export const openBuyTrades = async ({
  userId,
  metalType,
  tradeType,
  baseMetalWeight,
  livePrice,
  quantity,
  token,
  vendorId,
}: UserMutationProps) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_STAGING}/opportunity/virtual-trading/external/open`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "X-VendorId": `${vendorId}`,
      },
      body: JSON.stringify({
        userId,
        metalType,
        tradeType,
        baseMetalWeight,
        livePrice,
        quantity,
        tickId: null,
        isMutated: false,
      }),
    }
  )

  if (!response.ok) {
    throw { status: response.status, response: response.statusText }
  }

  const data = await response.json()
  return data
}
