import { useQuery } from "@tanstack/react-query"

export const useGenerateStatement = (
  userId: any,
  token: string,
  vendorId: any
) => {
  const { data, isPending, error, refetch } = useQuery({
    queryKey: ["generateStatement", userId],
    queryFn: async () => {
      const url = `${process.env.NEXT_PUBLIC_API_STAGING}/opportunity/virtual-trading/generate-statement-data`
      const res = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "X-VendorId": vendorId.toString(),
        },
        body: JSON.stringify({
          userId: userId,
        }),
      })
      if (!res.ok) {
        throw new Error("Failed to fetch user info!")
      }
      const data = await res.json()
      return data
    },
    enabled: !!userId,
  })

  return {
    data,
    isPending,
    error,
    refetch,
  }
}
