"use client"

import React, { createContext, useContext, useEffect, useState } from "react"

import { customLogout, getToken, initKeycloak, keycloak, logout } from "../config/keycloak.ts"

const KeycloakContext = createContext({
  initialized: false,
  authenticated: false,
  user: null,
  logout: () => { },
  loading: true,
  tradeToken: "", // Add tradeToken to the context
})

export const KeycloakProvider = ({ children }) => {
  const [initialized, setInitialized] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)
  const [user, setUser] = useState(null)
  const [token, setToken] = useState("")
  const [loading, setLoading] = useState(true)
  const [tradeToken, setTradeToken] = useState("") // State for trade token

  // Function to fetch the trade token using the tokenNew (Keycloak token)
  const fetchTradeToken = async (tokenNew) => {
    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_USER_URL}/user/trade_token`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenNew}`, // Pass Keycloak token in the Authorization header
          },
        }
      )

      if (!response.ok) {
        throw new Error("Failed to fetch trade token")
      }

      const data = await response.json()
      setTradeToken(data?.data?.TradeToken) // Store the trade token in state
    } catch (err) {
      console.error("Error fetching trade token:", err)
    }
  }

  useEffect(() => {
    const initializeKeycloak = async () => {
      try {
        if (typeof window !== "undefined") {
          let auth = await initKeycloak()

          setAuthenticated(auth)
          if (keycloak && auth) {
            setUser({
              name: keycloak.tokenParsed?.name,
              email: keycloak.tokenParsed?.email,
              userId: keycloak.tokenParsed?.sub,
            })

            const tokenNew = await getToken() // Get the Keycloak token
            setToken(tokenNew)

            // Use the Keycloak token to fetch the trade token
            await fetchTradeToken(tokenNew)

            setTimeout(() => {
              setLoading(false)
            }, Math.floor(Math.random() * (500 - 300)) + 300)
          }

          setInitialized(true)
        }
      } catch (err) {
        console.error("Failed to initialize Keycloak", err)
      }
    }

    initializeKeycloak()
  }, [])

  return (
    <KeycloakContext.Provider
      value={{
        initialized,
        authenticated,
        user,
        token,
        logout: customLogout,
        loading,
        tradeToken,
      }} // Provide tradeToken in the context
    >
      {children}
    </KeycloakContext.Provider>
  )
}

export const useKeycloak = () => useContext(KeycloakContext)
