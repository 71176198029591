import React, { useEffect, useRef, useState } from "react"
import { AnimatePresence } from "framer-motion"
import {
  CheckIcon,
  ClockIcon,
  CopyIcon,
  DownloadIcon,
  Loader2Icon,
  PaperclipIcon,
  ReplyIcon,
  TrashIcon, XIcon
} from "lucide-react"
import { useTheme } from "next-themes"
import { BsThreeDotsVertical } from "react-icons/bs"

import { cn } from "@/lib/utils"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

import { Button } from "../ui/button"
import { Label } from "../ui/label"
import ChatBottombarPrev from "./chat-bottombar-previous"
import { useToast } from "../ui/use-toast"
import { Message } from "@/app/admin/inbox/data"
import AudioPlayer from "./chat-custom/audio-player"
import CustomAvatar from "./chat-custom/custom-avatar"
import CustomAvatarAdmin from "./chat-custom/custom-avatar-admin"
import { TextProcessor } from "./chat-custom/TextProcessor"

function formatFileSize(bytes: number) {
  const ONE_MB = 1024 * 1024 // 1 MB in bytes
  const ONE_KB = 1024 // 1 KB in bytes

  if (bytes >= ONE_MB) {
    return (bytes / ONE_MB).toFixed(2) + " MB"
  } else {
    return (bytes / ONE_KB).toFixed(2) + " KB"
  }
}

const ImageWithLoading = ({ src, alt, className, onClick }: { src: string, alt: string, className: string, onClick?: () => void }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setError(false);
  }, [src]);

  return (
    <div className="relative">
      {isLoading || error && (
        <div className="absolute inset-0 flex items-center justify-center border border-border/40 bg-background/50 backdrop-blur-md supports-[backdrop-filter]:bg-background/10">
          <Loader2Icon className="h-8 w-8 animate-spin" />
        </div>
      )}
      <img
        src={src}
        alt={alt}
        className={cn(className, isLoading || error ? "invisible" : "visible")}
        onLoad={() => setIsLoading(false)}
        onError={() => {
          setIsLoading(false);
          setError(true);
        }}
        onClick={onClick}
      />
    </div>
  );
};

const truncateFileName = (name: string, maxLength: number) => {
  if (name.length <= maxLength) return name;

  const extIndex = name.lastIndexOf('.');
  const extension = extIndex !== -1 ? name.slice(extIndex) : '';
  const baseName = name.slice(0, extIndex);

  const front = baseName.slice(0, Math.ceil(maxLength / 2));
  const back = baseName.slice(-Math.floor(maxLength / 2));

  return `${front}...${back}${extension}`;
};

const DocumentWithLoading = ({ documentUrl, fileName, fileSize, onDownload }: { documentUrl: string; fileName: string; fileSize: number; onDownload: () => void }) => {
  return (
    <div className="relative">
      <div className="flex items-center justify-between gap-4 rounded-lg border border-border p-4">
        <div className="flex items-center space-x-2">
          <PaperclipIcon className="h-6 w-6 text-primary" />
          <div className="flex flex-col">
            <span className="font-medium truncate-middle">{truncateFileName(fileName, 15)}</span>
            <span className="text-sm text-muted-foreground">{formatFileSize(fileSize)}</span>
          </div>
        </div>
        <Button variant="outline" size="sm" onClick={onDownload}>
          <DownloadIcon className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

interface ChatListProps {
  messages: any
  selectedUser: any
  sendMessage: (message: Message) => void
  isMobile: boolean
  mySide: string
  setMessages: (m: any) => void
  conversationId: string
  isMessageDataPending: boolean
  handleTradeFromMessage: any
  refetchConv: () => void
  handleSeenEvent: () => void
  myMessages: any
  isMyConversation: boolean
}

type Media = {
  path: string;
};

interface ImageGalleryProps {
  message?: {
    media: Media[];
  };
}

export function extractFileName(filePath: string) {
  // Use split to divide the path by slashes and backslashes and return the last element (the file name)
  const parts = filePath?.split(/[/\\]/);
  return parts?.[parts?.length - 1];
}

function sanitizePath(path: string) {
  // Use a regular expression to replace double dots with a single dot or remove them
  const sanitizedPath = path?.replace(/\.\.\//g, '');

  return sanitizedPath;
}
export function formatDateTime(dateTimeString: string) {
  const date = new Date(dateTimeString);

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Check for NaN values and fallback to the current time if NaN is found
  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0'); // Convert 0 to 12 for 12 AM
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'pm' : 'am';

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;
}

export function ChatList({
  messages,
  selectedUser,
  sendMessage,
  isMobile,
  mySide,
  setMessages,
  conversationId,
  isMessageDataPending,
  handleTradeFromMessage,
  refetchConv,
  handleSeenEvent,
  myMessages,
  isMyConversation
}: ChatListProps) {
  const messagesContainerRef = useRef<HTMLDivElement>(null)
  const { theme } = useTheme()
  const [hoveredMessageIndex, setHoveredMessageIndex] = useState<number | null>(
    null
  )
  const [openReply, setOpenReply] = useState(false)
  const [openPopoverIndex, setOpenPopoverIndex] = useState<number | null>(null)
  const [openAttachDialog, setOpenAttachDialog] = useState(false)
  const [attachQty, setAttachQty] = useState(0)
  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null)
  const [replySenderName, setReplySenderName] = useState<string>("")
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const { toast } = useToast()
  const inputRef = useRef<HTMLTextAreaElement>(null)



  const closeReply = () => {
    setOpenReply(false)
    setReplySenderName("");
    setSelectedMessage(null)
    setOpenPopoverIndex(null)
  }

  const closeAttachDialog = () => {
    setAttachQty(0)
    setOpenAttachDialog(false)
  }

  const handleReplyClick = (message: any, senderName: string) => {
    setReplySenderName(senderName);
    setSelectedMessage(message)
    setOpenReply(true)
    setOpenPopoverIndex(null)
    inputRef.current?.focus()
  }

  const onTrdeClose = (tradeId: string) => {
    if (handleTradeFromMessage) {
      handleTradeFromMessage(tradeId)
      setOpenPopoverIndex(null)
    }
  }

  const handleClickCopy = (message: any) => {
    navigator.clipboard.writeText(message.message)
    toast({
      variant: "default",
      className: "bg-green-600 text-white hover:bg-green-700",
      description: "Copied to clipboard!",
    });
    setOpenPopoverIndex(null)
  }

  React.useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight
    }
    // console.log("messages", messages)
  }, [messages])

  function downloadFile(url: string, fileName: string) {
    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = url;

    // Force the browser to download the file instead of navigating to it or previewing
    link.setAttribute('download', fileName);

    // Use Blob object to bypass issues where content is previewed instead of downloaded
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const objectURL = URL.createObjectURL(blob);
        link.href = objectURL;

        // Programmatically click the anchor to trigger the download
        link.click();

        // Clean up the URL object after download
        URL.revokeObjectURL(objectURL);
      })
      .catch(error => {
        toast({
          variant: "destructive",
          description: "File download failed! Please try again.",
        });
      });
  }

  const handleImageClick = (src: string) => {
    setSelectedImage(src);
  };

  const handleCloseImage = () => {
    setSelectedImage(null);
  };

  const handleDownloadImage = async () => {
    if (selectedImage) {
      try {
        // Fetch the image as a blob
        const response = await fetch(selectedImage);
        const blob = await response.blob();

        // Create a temporary link element
        const link = document.createElement('a');

        // Create a URL for the blob and set it as href
        const url = URL.createObjectURL(blob);
        link.href = url;

        // Set the download attribute with a default file name
        const fileName = selectedImage.split('/').pop() || 'image';
        link.download = fileName;

        // Append the link to the document
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up: remove the link and revoke the blob URL
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading the image:", error);
      }
    }
  };

  const renderAvatar = (message: Message, isUserMessage: boolean) => (
    isUserMessage ?
      <CustomAvatar src={message.avatar} alt={message.avatar} icon={null} size={6} />
      :
      <CustomAvatarAdmin src={message.avatar} alt={message.avatar} icon={null} size={6} />
  )

  const renderSystemMessage = (message: any) => (
    <div className="flex items-center justify-center my-4">
      <div className="bg-accent/40 text-muted-foreground px-3 py-1 rounded-full text-[11px] font-light">
        <TextProcessor text={message.message} />
      </div>
    </div>
  )

  const renderRegularMessage = (message: any, isLastIndex: boolean) => {
    const isUserMessage = message?.isMySide;
    const index = messages.findIndex((item: any) => item.id === message?.id);

    const messageContext = messages?.find((a: any) => a.id === message?.context);
    // console.log(messageContext);
    const senderName = messageContext?.isMySide
      ? `${(!selectedUser?.firstName && !selectedUser?.lastName) ? selectedUser?.phone : !selectedUser?.firstName ? selectedUser?.lastName : !selectedUser?.lastName ? selectedUser?.firstName : selectedUser?.firstName + " " + selectedUser?.lastName
      }`
      : `${messageContext?.agentInfo?.firstName} ${messageContext?.agentInfo?.lastName}`;

    const mediaContent =
      messageContext?.media?.length > 0 ? messageContext?.media?.[0]?.type === "image" ?
        <img src={`${process.env.NEXT_PUBLIC_API_USER_URL_IMAGE}/${messageContext?.media?.[0]?.path}`} alt="media" className="size-40 object-cover cursor-pointer" />
        : extractFileName(messageContext?.media?.[0].path)
        : messageContext?.message;

    return (
      <div
        className={cn(
          "flex flex-col gap-2 whitespace-pre-wrap p-4",
          isUserMessage ? "items-start" : "items-end"
        )}
        onMouseEnter={() => setHoveredMessageIndex(index)}
        onMouseLeave={() => setHoveredMessageIndex(null)}
      >
        <div
          className={`flex items-end gap-3 ${isUserMessage ? "ml-12" : "mr-12"
            }`}
        >
          <div className="flex items-center gap-4">
            {!isUserMessage && renderActions(message?.id, message, message?.agentInfo?.firstName ? `${message?.agentInfo?.firstName} ${message?.agentInfo?.lastName}` : message?.agentType === "System" ? "System" : "You", mediaContent)}

            <span
              className={`${message?.media?.length > 0
                ? ""
                : isUserMessage
                  ? "bg-accent/60"
                  : "bg-primary/20"
                } max-w-xs rounded-md p-3 shadow-md`}
            >
              {message?.isFile ? (
                message?.messageType === "audio" ? (
                  <>
                    {message.media.map((attachment: any, index: number) => (
                      attachment.path ?
                        <AudioPlayer key={index} audioUrl={`${process.env.NEXT_PUBLIC_API_USER_URL_IMAGE}/${sanitizePath(attachment.path)}`} /> :
                        <div className="flex items-center space-x-2 rounded-md border bg-background/50 p-2 backdrop-blur-sm">
                          <Loader2Icon className="h-5 w-5 animate-spin" />
                          <span>Loading audio...</span>
                        </div>
                    ))}
                  </>
                ) : (
                  <>
                    {message?.messageType === "document" && (
                      <>
                        {message.media.map((attachment: any, index: number) => (
                          attachment.path ?
                            <DocumentWithLoading
                              key={index}
                              documentUrl={`${process.env.NEXT_PUBLIC_API_USER_URL_IMAGE}/${attachment.path}`}
                              fileName={extractFileName(attachment.path)}
                              fileSize={attachment.size || 56587}
                              onDownload={() => downloadFile(`${process.env.NEXT_PUBLIC_API_USER_URL_IMAGE}/${attachment.path}`, extractFileName(attachment.path))}
                            /> :
                            <div className="flex items-center space-x-2 rounded-md border bg-background/50 p-2 backdrop-blur-sm">
                              <Loader2Icon className="h-5 w-5 animate-spin" />
                              <span>Loading document...</span>
                            </div>
                        ))}
                      </>
                    )}

                    {(message?.messageType === "image" || message?.messageType === "sticker") && (
                      <div className={`mt-2 grid ${message.media.length > 1 ? "grid-cols-2" : ""} gap-2`}>
                        {message.media.map((src: any, index: number) => {
                          const sanitizedSrc = sanitizePath(src.path);
                          const imageUrl = src.path ? `${process.env.NEXT_PUBLIC_API_USER_URL_IMAGE}/${sanitizedSrc}` : "";
                          return (
                            <div key={index} className="flex justify-center">
                              {src.path ? (
                                <ImageWithLoading
                                  src={imageUrl}
                                  alt={`preview-${index}`}
                                  className="size-40 object-cover cursor-pointer"
                                  onClick={() => handleImageClick(imageUrl)}
                                />
                              ) : (
                                <div className="flex size-40 items-center justify-center border border-border/40 bg-background/50 backdrop-blur-md supports-[backdrop-filter]:bg-background/10">
                                  <Loader2Icon className="h-8 w-8 animate-spin text-gray-500" />
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}


                    {message?.message?.length > 0 && (
                      <p className="ml-1 mt-4 break-words">
                        {message?.message}
                      </p>
                    )}
                  </>
                )
              ) : message?.context?.length > 0 ? (

                <div className="flex items-center gap-4">
                  <span
                    className={`${!isUserMessage ? "bg-accent/60" : "bg-primary/20"
                      } max-w-full rounded-md bg-accent p-3 shadow-md`}
                  >
                    <div
                      className={`${!isUserMessage ? "bg-primary/20" : "bg-accent/60"
                        } rounded-md border-l-2 border-green-500 p-3 text-justify shadow-md`}
                    >
                      <h3
                        className={`${!isUserMessage ? "text-left" : "text-right"
                          } mb-3 text-green-600`}
                      >
                        {senderName}
                      </h3>
                      <span className="break-words">{mediaContent}</span>
                    </div>
                    <div className="pt-3 break-words">{message?.message}</div>
                  </span>
                </div>
              ) : (
                <span className="break-words">{message?.message}</span>
              )}
            </span>

            {isUserMessage && renderActions(message?.id, message, selectedUser?.firstName, mediaContent)}
          </div>
        </div>
        {renderMessageDetails(message, isUserMessage, isLastIndex)}
      </div>
    );
  }

  const renderMessageGroups = () => {
    let currentGroup: any[] = [];
    const groups: any[][] = [];
    messages.forEach((message: any, index: number) => {
      if (message?.sender === "Distribution_logger") {
        if (currentGroup.length > 0) {
          groups.push(currentGroup);
          currentGroup = [];
        }
        groups.push([message]);
      } else {
        currentGroup.push(message);
      }
    });

    if (currentGroup.length > 0) {
      groups.push(currentGroup);
    }

    return groups.map((group, groupIndex) => (
      <React.Fragment key={groupIndex}>
        {group[0]?.sender === "Distribution_logger" ? (
          renderSystemMessage(group[0])
        ) : (
          group.map((message, messageIndex) =>
            renderRegularMessage(message, messageIndex === group.length - 1)
          )
        )}
      </React.Fragment>
    ));
  }

  const renderActions = (messageId: string, message: any, senderName: string, mediaContent: any) => {
    const regex = /#\d{5}/;
    const match = message?.message?.match(regex) || mediaContent?.match(regex); // Extract the match from the message
    const isCloseTradeOption = !!match; // Check if the match exists
    const closeTradeNumber = match ? match[0].substring(1) : null;
    const index = messages.findIndex((item: any) => item.id === messageId);

    return (
      <Popover
        open={openPopoverIndex === index}
        onOpenChange={(isOpen) => setOpenPopoverIndex(isOpen ? index : null)}
      >
        <PopoverTrigger asChild>
          <div>
            <BsThreeDotsVertical
              size={18}
              className={`cursor-pointer transition-opacity duration-200 ${hoveredMessageIndex === index ? "opacity-100" : "opacity-0"
                }`}
            />
          </div>
        </PopoverTrigger>
        <PopoverContent className="mt-4 w-44">
          <PopContent
            handleClickReply={() => handleReplyClick(message, senderName)}
            handleClickCopy={() => handleClickCopy(message)}
            isCloseTradeOption={isCloseTradeOption}
            tradeNumber={closeTradeNumber}
            onTrdeClose={onTrdeClose}
          />
        </PopoverContent>
      </Popover>
    )
  }

  const renderMessageDetails = (message: any, isUserMessage: boolean, isLastIndex: boolean) => {
    const sentTime = formatDateTime(message?.sentTime);
    const isPending = isLastIndex && isMessageDataPending;
    const userName = `${selectedUser?.firstName || ''} ${selectedUser?.lastName || ''}`.trim();
    const agentName = message?.agentInfo?.firstName?.length > 0
      ? `${message.agentInfo.firstName} ${message.agentInfo.lastName}`
      : message?.agentType === "System" ? "System" : "You";

    return (
      <h3 className="flex flex-row items-center gap-5 text-xs text-muted-foreground/70">
        {isUserMessage && renderAvatar(message, true)}
        {isUserMessage ? (
          <>
            <b>{userName}</b> {sentTime}
            {isPending ? <ClockIcon size={14} /> : <CheckIcon className="text-blue-400" size={14} />}
          </>
        ) : (
          <>
            {isPending ? <ClockIcon size={14} /> : <CheckIcon className="text-blue-400" size={14} />}
            {sentTime} <b>{agentName}</b>
          </>
        )}
        {!isUserMessage && renderAvatar(message, false)}
      </h3>
    );
  };

  return (
    <div className="group flex size-full flex-col overflow-y-auto overflow-x-hidden pt-4">
      <div
        ref={messagesContainerRef}
        className="scrollbar-thin scrollbar-thumb-rounded scrollbar-track-transparent flex size-full flex-col overflow-y-auto overflow-x-hidden pt-20"
      >
        <div
          className={`absolute inset-0 z-0 bg-[url('/images/bg-pattern-3.png')] bg-top bg-repeat ${theme === "dark" ? "opacity-30" : "opacity-60"
            }`}
        ></div>

        <div className="z-9 relative">
          <AnimatePresence>
            {renderMessageGroups()}
          </AnimatePresence>
        </div>
      </div>
      {selectedImage && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70"
          onClick={handleCloseImage}
        >
          <div className="relative" onClick={(e) => e.stopPropagation()}>
            <img
              src={selectedImage}
              alt="Full-screen view"
              className="max-h-[80vh] max-w-[90vw] object-contain"
            />
            <Button
              className="absolute right-4 top-4"
              onClick={handleCloseImage}
              variant="outline"
            >
              <XIcon className="size-4" />
            </Button>
            <Button
              variant="outline"
              className="absolute right-20 top-4"
              onClick={handleDownloadImage}
            >
              <DownloadIcon className="size-4" />
            </Button>
          </div>
        </div>
      )}
      <ChatBottombarPrev
        openAttachDialog={openAttachDialog}
        setOpenAttachDialog={setOpenAttachDialog}
        closeAttachDialog={closeAttachDialog}
        attachQty={attachQty}
        setAttachQty={setAttachQty}
        sendMessage={sendMessage}
        isMobile={isMobile}
        openReply={openReply}
        closeReply={closeReply}
        senderId={mySide}
        agentId=""
        selectedMessage={selectedMessage}
        setSelectedMessage={setSelectedMessage}
        replySenderName={replySenderName}
        messages={messages}
        setMessages={setMessages}
        conversationId={conversationId}
        selectedUser={selectedUser}
        refetchConv={refetchConv}
        handleSeenEvent={handleSeenEvent}
        inputRef={inputRef}
        myMessages={myMessages}
        isMyConversation={isMyConversation}
      />
    </div>
  )
}

export default ChatList

const PopContent = React.memo(({ handleClickReply, isCloseTradeOption, handleClickCopy, tradeNumber, onTrdeClose }: any) => {
  return (
    <div>
      <Button
        variant="ghost"
        onClick={handleClickReply}
        className="flex w-full flex-row justify-between border-none"
      >
        <Label>Reply</Label>
        <ReplyIcon size={14} />
      </Button>
      <Button
        variant="ghost"
        className="flex w-full flex-row justify-between border-none"
        onClick={handleClickCopy}
      >
        <Label>Copy</Label>
        <CopyIcon size={14} />
      </Button>
      {
        isCloseTradeOption &&
        <Button
          variant="ghost"
          className="flex w-full flex-row justify-between border-none"
          onClick={() => onTrdeClose(tradeNumber)}
        >
          <Label>Close #{tradeNumber}</Label>
          <TrashIcon size={14} />
        </Button>
      }
    </div>
  )
})
