import { getToken } from "@/auth/config/keycloak"
import { useMutation } from "@tanstack/react-query"

interface IEventMutationProps {
  conversationId: string
  event: string
}

interface IConvSeen {
  onSuccess?: (data: any) => void
  onError?: (error: Error) => void
}

const convSeen = async ({
  conversationId, event
}: IEventMutationProps) => {
  const token = getToken()

  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_SUPPORT_URL}/support/${conversationId}/event`,
    {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        event
      }),
    }
  )

  if (!response.ok) {
    throw { status: response.status, response: response.body }
  }

  const data = await response.json()
  return data
}

export const useConvSeen = (options: IConvSeen = {}) => {
  return useMutation({
    mutationFn: convSeen,
    onSuccess: options.onSuccess,
    onError: options.onError,
  })
}
