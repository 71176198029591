import { getToken } from "@/auth/config/keycloak"
import { useKeycloak } from "@/auth/provider/KeycloakProvider";
import { useQueries, useQuery } from "@tanstack/react-query"

function removePlusFromNumber(phoneNumber: string) {
  return phoneNumber?.replace('+', '');
}

export const useGetUsers = (ids: string[]) => {
  const token = getToken()
  const { tradeToken } = useKeycloak();
  // Fetch user by ID and then fetch user by phone number (if available)
  return useQueries({
    queries: ids?.map((id) => ({
      queryKey: ["user", id],
      queryFn: async () => {
        // Fetch user data by ID
        const res = await fetch(
          `${process.env.NEXT_PUBLIC_API_USER_URL}/user/${id}`,
          {
            cache: "no-store",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        if (!res.ok) {
          throw new Error("Failed to fetch user")
        }
        const userData = await res.json()
        const user = userData?.data?.User

        // Check if the user has a phone number
        const phone = user?.attributes?.phone?.[0] || null
        let phoneData = null

        // If phone number exists, fetch additional data by phone
        if (phone) {
          const phoneN = removePlusFromNumber(phone)
          const phoneRes = await fetch(
            `${process.env.NEXT_PUBLIC_API_STAGING}/external/user-by-phone?phone=${phoneN}`,
            {
              cache: "no-store",
              headers: {
                Authorization: `Bearer ${tradeToken}`,
                "Content-Type": "application/json",
              },
            }
          )

          if (phoneRes.ok) {
            phoneData = await phoneRes.json()
          }
        }

        // Merge the two responses (user data and phone data)
        return {
          ...user,
          phoneData, // Add phone data to the user object
        }
      },
      enabled: !!id,
    })),
  })
}
