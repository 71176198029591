import React, { useState } from "react";
import { Mic, SendIcon } from "lucide-react";
import { Button } from "@/components/ui/button";

interface RecordButtonProps {
  onStartRecording: () => void;
  onStopRecording: () => void;
  isRecording: boolean;
}

const RecordButton: React.FC<RecordButtonProps> = ({
  onStartRecording,
  onStopRecording,
  isRecording,
}) => {
  return (
    <Button onClick={isRecording ? onStopRecording : onStartRecording} size="icon" variant="outline" className="mr-3">
      {isRecording ? <SendIcon size={20} /> : <Mic size={20} />}
    </Button>
  );
};

export default RecordButton;