import { useMutation } from "@tanstack/react-query";
import { openPendingTrades } from "./openPendingTrades";

interface MutationOptionProps {
  onSuccess?: (data: any) => void;
  onError?: (error: Error) => void;
}

export const useOpenPendingTrades = (options: MutationOptionProps = {}) => {
  return useMutation({
    mutationFn: openPendingTrades,
    onSuccess: options.onSuccess,
    onError: options.onError,
    retry: 3, // Retries 3 times on failure (default behavior)
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000), // Exponential backoff (1s, 2s, 4s, up to 30s)
  });
};
