import { getToken } from "@/auth/config/keycloak"
import { useMutation } from "@tanstack/react-query"

interface IUserMutationProps {
  conversationId: string
  content: string
  media?: any
  contextId?: any
  ManualType?: any
}

interface IAddMessage {
  onSuccess?: (data: any) => void
  onError?: (error: Error) => void
}
const addMessage = async ({
  conversationId,
  content,
  media,
  contextId,
  ManualType
}: IUserMutationProps) => {
  const token = getToken()

  // Use FormData to send both text and files
  const formData = new FormData()
  formData.append("content", content || "") // Append the text content
  formData.append("recipientId", conversationId) // Append recipientId
  formData.append("agentType", "Human") // Append agent type
  contextId && formData.append("contextId", contextId) // Append contextId
  ManualType && formData.append("ManualType", ManualType) // Append ManualType

  // Append all media files under the same "media" key
  if (media && media.length > 0) {
    media.forEach((file: File) => {
      if (file instanceof File) {
        formData.append("media", file) // Append each file to 'media'
      }
    })
  }

  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_SUPPORT_URL}/support/${conversationId}/messages`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        // Do NOT set Content-Type when sending FormData
      },
      body: formData, // Send the FormData object with files and text
    }
  )

  if (!response.ok) {
    throw { status: response.status, response: await response.text() }
  }

  const data = await response.json()
  return data
}

export const useAddMessage = (options: IAddMessage = {}) => {
  return useMutation({
    mutationFn: addMessage,
    onSuccess: options.onSuccess,
    onError: options.onError,
  })
}
