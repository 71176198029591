"use client"
import { Button } from "@/components/ui/button"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Search, SendIcon } from "lucide-react"
import { useState } from "react"
import { Input } from "../ui/input"

interface Template {
    id: string
    name: string
}

interface TemplateListProps {
    templates: Template[]
    onSend: (templateId: string) => void
}

export function TemplateList({ templates, onSend }: TemplateListProps) {
    const [searchText, setSearchText] = useState("")
    const handleSearch = (value: string) => {
        console.log(value)
    }
    return (
        <>
            <div className="relative mt-[-4px]">
                <Input
                    type="text"
                    placeholder="Search here.."
                    className="rounded-md border pr-10 focus-visible:border-gray-400 focus-visible:ring-0 focus-visible:ring-offset-0"
                    value={searchText}
                    onChange={(e) => handleSearch(e.target.value)}
                />
                <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center pr-3">
                    <Search className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
            </div>
            <ScrollArea className="h-[260px] w-full rounded-md border">
                <div className="px-4 pb-4">
                    {templates.map((template) => (
                        <div key={template.id} className="flex items-center justify-between py-2">
                            <span className="text-sm font-medium">{template.name}</span>
                            <Button
                                onClick={() => onSend(template.name)}
                                size="icon"
                                variant="outline"
                                className="mr-3"
                            >
                                <SendIcon size={20} />
                            </Button>
                        </div>
                    ))}
                </div>
            </ScrollArea>
        </>
    )
}