import React, { useEffect, useRef, useState } from "react";
import { PlayCircleIcon, PauseCircleIcon, HeadphonesIcon } from "lucide-react";

interface AudioPlayerProps {
  audioUrl: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ audioUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const wasPlayingBeforeSeek = useRef(false);

  // Remove the "../" from the URL
  const formattedUrl = audioUrl.replace(/\.\.\//, '');

  useEffect(() => {
    const audio = new Audio();
    audioRef.current = audio;
    const handleLoadedMetadata = () => {
      if (!isNaN(audio.duration)) {
        setDuration(audio.duration);
        setIsLoaded(true);
      } else {
        // Retry fetching duration in case it's not available yet
        const retryDurationFetch = () => {
          if (audioRef.current && !isNaN(audioRef.current.duration) && audioRef.current.duration > 0) {
            setDuration(audioRef.current.duration);
            setIsLoaded(true);
          } else {
            setTimeout(retryDurationFetch, 500); // Retry after 500ms
          }
        };
        retryDurationFetch();
      }
    };

    const handleCanPlayThrough = () => {
      if (!isNaN(audio.duration)) {
        setDuration(audio.duration);
        setIsLoaded(true);
      }
    };

    const handleTimeUpdate = () => {
      if (!isSeeking) {
        setCurrentTime(audio.currentTime);
      }
    };

    // When audio ends, reset the player
    const handleAudioEnd = () => {
      setCurrentTime(0);
      setIsPlaying(false);
    };

    const handleError = () => {
      setError("Error loading audio");
      setIsLoaded(false);
    };

    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('canplaythrough', handleCanPlayThrough);
    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('ended', handleAudioEnd); 
    audio.addEventListener('error', handleError);

    // Set crossOrigin attribute for audio loading
    audio.crossOrigin = "anonymous";
    audio.src = formattedUrl;

    // Attempt to load the audio
    audio.load();

    return () => {
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('canplaythrough', handleCanPlayThrough);
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('ended', handleAudioEnd);
      audio.removeEventListener('error', handleError);
      audio.pause();
    };
  }, [formattedUrl, isSeeking]);

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch(e => {
          setError("Error playing audio");
        });
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleSeek = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = (Number(event.target.value) / 100) * duration;
    setCurrentTime(newTime);
    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
    }
  };

  const handleSeekStart = () => {
    setIsSeeking(true);
    wasPlayingBeforeSeek.current = isPlaying;
    if (audioRef.current && isPlaying) {
      audioRef.current.pause();
    }
  };

  const handleSeekEnd = () => {
    setIsSeeking(false);
    if (wasPlayingBeforeSeek.current && audioRef.current) {
      audioRef.current.play().catch(e => {
        setError("Error playing audio");
      });
      setIsPlaying(true);
    }
  };

  const formatTime = (seconds: number) => {
    if (isNaN(seconds) || !isFinite(seconds)) return "00:00";
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  const playedPercentage = duration > 0 ? (currentTime / duration) * 100 : 0;

  return (
    <div className="flex items-center justify-between gap-4 rounded-lg border border-gray-400 p-4 w-[300px]">
      <div className="flex flex-col items-center justify-center rounded-full shadow bg-gray-600 p-2">
        <HeadphonesIcon size={18} className="text-white-400" />
      </div>
      <div className="flex-1">
        <input
          type="range"
          value={playedPercentage}
          max="100"
          onChange={handleSeek}
          onMouseDown={handleSeekStart}
          onMouseUp={handleSeekEnd}
          onTouchStart={handleSeekStart}
          onTouchEnd={handleSeekEnd}
          className="w-full h-2 appearance-none cursor-pointer"
          style={{
            background: `linear-gradient(to right, #4caf50 ${playedPercentage}%, #d3d3d3 ${playedPercentage}%)`,
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
          }}
        />
        <p className="mt-2 text-xs text-gray-300">
          {formatTime(currentTime)} / {formatTime(duration)}
        </p>
        {error && <p className="mt-2 text-xs text-red-500">{error}</p>}
      </div>
      {isPlaying ? (
        <PauseCircleIcon
          title="Pause"
          onClick={handlePlayPause}
          size={24}
          className="cursor-pointer text-gray-400"
        />
      ) : (
        <PlayCircleIcon
          title="Play"
          onClick={handlePlayPause}
          size={24}
          className={`cursor-pointer text-gray-400 ${!isLoaded ? 'opacity-50 cursor-not-allowed' : ''}`}
        />
      )}
    </div>
  );
};

export default AudioPlayer;