import { useMutation } from "@tanstack/react-query";

interface UserMutationProps {
    token: string;
    userId: number;
    vendorId: number;
    action?: string; // Make action optional
}

export const triggerAutoStatement = async ({ token, userId, vendorId, action }: UserMutationProps) => {
    // Construct body dynamically to exclude `action` if it's not provided
    const body: Record<string, any> = { userId };
    if (action) {
        body.action = action;
    }

    const response = await fetch(`${process.env.NEXT_PUBLIC_API_STAGING}/opportunity/virtual-trading/auto-statement-status`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'X-Vendor-Id': `${vendorId}`
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        throw { status: response.status, response: response.statusText };
    }

    const data = await response.json();
    return data;
};

interface MutationOptionProps {
    onSuccess?: (data: any) => void;
    onError?: (error: Error) => void;
}

export const useAutoStatement = (options: MutationOptionProps = {}) => {
    return useMutation({
        mutationFn: triggerAutoStatement,
        onSuccess: options.onSuccess,
        onError: options.onError,
        retry: 3, // Retries 3 times on failure (default behavior)
        retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 20000),
    });
};
