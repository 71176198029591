import { useState, useCallback, useEffect, useRef } from 'react';
import MicRecorder from 'mic-recorder-to-mp3';

export const useAudioRecorder = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState<string | null>(null);
  const [recordedFile, setRecordedFile] = useState<File | null>(null);
  const [timer, setTimer] = useState(0);

  const timerRef = useRef(0); // Keep track of the timer value
  const timerIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);

  const recorder = useRef<MicRecorder | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);

  // Start recording with audio analysis
  const startRecording = useCallback(async () => {
    if (!recorder.current) {
      recorder.current = new MicRecorder({ bitRate: 128 });
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStreamRef.current = stream;

      // Setup AudioContext and AnalyserNode
      audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
      const source = audioContextRef.current.createMediaStreamSource(stream);
      const analyser = audioContextRef.current.createAnalyser();
      analyser.fftSize = 2048; // Adjust FFT size for frequency resolution
      source.connect(analyser);
      analyserRef.current = analyser;

      // Start recording
      await recorder.current.start();
      setIsRecording(true);
      timerRef.current = 0; // Reset timer ref
      setTimer(0);

      timerIntervalRef.current = setInterval(() => {
        timerRef.current += 1;
        setTimer(timerRef.current); // Update the timer state
      }, 1000);
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  }, []);

  // Stop recording and process MP3 file
  const stopRecording = useCallback(async () => {
    if (recorder.current && isRecording) {
      try {
        const [buffer, blob] = await recorder.current.stop().getMp3();

        // Create an MP3 file
        const file = new File(buffer, `recording_${Date.now()}.mp3`, {
          type: blob.type,
          lastModified: Date.now(),
        });

        // Create a URL for playback
        const url = URL.createObjectURL(blob);
        setAudioURL(url);
        setRecordedFile(file);
      } catch (error) {
        console.error('Error stopping recording:', error);
      } finally {
        setIsRecording(false);
        if (timerIntervalRef.current) {
          clearInterval(timerIntervalRef.current);
        }

        // Stop and clean up the media stream and audio context
        mediaStreamRef.current?.getTracks().forEach((track) => track.stop());
        audioContextRef.current?.close();
        analyserRef.current = null;
      }
    }
  }, [isRecording]);

  // Reset recording state
  const resetRecording = useCallback(() => {
    if (audioURL) {
      URL.revokeObjectURL(audioURL); // Clean up the generated URL
    }
    setAudioURL(null);
    setRecordedFile(null);
    setTimer(0);
    timerRef.current = 0; // Reset the timer reference
    setIsRecording(false);

    // Stop any ongoing media streams
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      mediaStreamRef.current = null;
    }

    // Close the audio context
    if (audioContextRef.current) {
      audioContextRef.current.close().catch((error) => console.error('Error closing AudioContext:', error));
      audioContextRef.current = null;
    }

    // Reset the analyser reference
    analyserRef.current = null;

    // Clear the timer interval
    if (timerIntervalRef.current) {
      clearInterval(timerIntervalRef.current);
      timerIntervalRef.current = null;
    }

    // Reset the recorder instance
    if (recorder.current) {
      recorder.current = null;
    }
  }, [audioURL]);


  useEffect(() => {
    return () => {
      if (audioURL) {
        URL.revokeObjectURL(audioURL);
      }
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
    };
  }, [audioURL]);

  return {
    isRecording,
    audioURL,
    recordedFile,
    timer,
    analyser: analyserRef.current,
    startRecording,
    stopRecording,
    resetRecording,
  };
};