import { useQuery } from "@tanstack/react-query"

export const getUsersByVendorId = (
  page: any,
  length: any,
  queryString: any,
  vendorId: number,
  token: string
) => {
  const { data, isPending, error, refetch } = useQuery({
    queryKey: ["usersByVendorId", page, queryString, vendorId],
    queryFn: async () => {
      const url = `${process.env.NEXT_PUBLIC_API_STAGING_VENDOR}/user?page=${page}&pageSize=${length}&queryString=${queryString}&status=active`
      const res = await fetch(url, {
        cache: "no-store",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          'X-VendorId': vendorId.toString(),
        },
      })
      if (!res.ok) {
        throw new Error("Failed to fetch users!")
      }

      const data = await res.json();
      return data;
    },
    enabled: vendorId > 0,
  })
  return {
    data,
    isPending,
    error,
    refetch,
  }
}