import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"

interface CustomAvatarProps {
  src?: string
  alt?: string
  className?: string
  icon: React.ReactNode
  size?: number
}

export default function CustomAvatar({ src, alt, className = "", icon, size = 12 }: CustomAvatarProps) {
  return (
    <div className="relative inline-block">
      <Avatar className={`h-${size} w-${size} ${className}`}>
        <AvatarImage src={src} alt={alt} />
        <AvatarFallback>
          {/* <div className="bg-gray-200 w-full h-full flex items-center justify-center">
            <svg
              className="h-8 w-8 text-gray-400"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </div> */}
          <img src="/User6.png" alt={alt} />
        </AvatarFallback>
      </Avatar>
      {/* <div className="absolute bottom-0 left-0 bg-white rounded-full p-1 shadow-md">
        {icon}
      </div> */}
    </div>
  )
}