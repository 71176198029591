import { Button } from "@/components/ui/button"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Search } from "lucide-react"
import { Input } from "../ui/input"
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar"
import { BiTransferAlt } from "react-icons/bi";

interface TemplateListProps {
    onSend: (userId: string) => void
    userOptions: any
    userSearchText: string
    handleSearchUser: (query: string) => void
}

export function TransferList({ onSend, userOptions, userSearchText, handleSearchUser }: TemplateListProps) {
    return (
        <>
            <div className="relative mt-[-4px]">
                <Input
                    type="text"
                    placeholder="Search here.."
                    className="rounded-md border pr-10 focus-visible:border-gray-400 focus-visible:ring-0 focus-visible:ring-offset-0"
                    value={userSearchText}
                    onChange={(e) => handleSearchUser(e.target.value)}
                />
                <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center pr-3">
                    <Search className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
            </div>
            <ScrollArea className="h-[260px] w-full rounded-md border">
                <div className="px-4 pb-4">
                    {userOptions?.map((user: any) => (
                        <div key={user.id} className="flex items-center justify-between py-2">
                            <div className="flex items-center gap-2">
                                <Avatar>
                                    <AvatarImage src={user.avatarUrl} alt={user.name} />
                                    <AvatarFallback>{user.name.charAt(0)}</AvatarFallback>
                                </Avatar>
                                <span className="text-sm font-medium">{user.name}</span>
                            </div>
                            <Button
                                onClick={() => onSend(user.id)}
                                size="sm"
                                variant="outline"
                                className="mr-3"
                            >
                                Assign<BiTransferAlt className="ml-2 h-4 w-4" />
                            </Button>
                        </div>
                    ))}
                </div>
            </ScrollArea>
        </>
    )
}