import { useQuery } from "@tanstack/react-query"

export const useGetUserByPhone = (params: { phone: string; token: string }) => {
  const { data, isPending, error, refetch } = useQuery({
    queryKey: ["getUserInfo", params.phone],
    queryFn: async () => {
      const url = `${process.env.NEXT_PUBLIC_API_STAGING}/external/user-by-phone?phone=${params.phone}`
      const res = await fetch(url, {
        cache: "no-store",
        headers: {
          Authorization: `Bearer ${params.token}`,
          "Content-Type": "application/json",
        },
      })
      if (!res.ok) {
        throw new Error("Failed to fetch user info!")
      }

      const data = await res.json()
      return data
    },
    enabled: !!params.phone,
  })

  return {
    data,
    isPending,
    error,
    refetch,
  }
}
