import { pdf } from "@react-pdf/renderer"

import { MyDocument, formatCurrentDateTime } from "./generateActivePDF"

export const generateAndSendPDF = async (statements, handleMessage) => {
  try {
    const blob = await pdf(<MyDocument data={statements} />).toBlob()
    const generatedFile = new File(
      [blob],
      `${statements?.summary?.name}_statement_${
        formatCurrentDateTime().currentDate
      }_${formatCurrentDateTime().currentTime}.pdf`,
      { type: "application/pdf" }
    )
    handleMessage(generatedFile)
  } catch (error) {
    console.error("Error generating PDF:", error)
  }
}
