import { FSDataType } from "@/utils/filter-sort-param";
import { useQueries, useQuery } from "@tanstack/react-query";
import { getToken } from "@/auth/config/keycloak";

interface GetOnlineUsersByLengthProps {
  length: number;
  page: number;
  fsStr: FSDataType | '';
  dependency?: any;
  businessId?: string
}

export const GetOnlineUsersByLength = ({ length = 10, page = 0, fsStr = '', dependency, businessId }: GetOnlineUsersByLengthProps) => {
  const token = getToken();

  const { data: onlineUsers, isLoading: loadingOnlineUsers, error: onlineUsersError, refetch } = useQuery({
    queryKey: ['onlineUsers', length, page, fsStr, dependency, businessId],
    queryFn: async () => {
      const res = await fetch(`${process.env.NEXT_PUBLIC_API_SUPPORT_URL}/support/online/${businessId}?length=${length}&page=${page}${fsStr}`, {
        cache: 'no-store',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      if (!res.ok) {
        throw new Error('Failed to fetch users');
      }
      const data = await res.json();
      return data?.data?.onlineUsers;  // Assuming data format from response
    },
    enabled: !!length,
  });

  // Retrieve additional user details based on userId from the online users list
  const userDetailsQueries = useQueries({
    queries: (onlineUsers || []).map((user: any) => ({
      queryKey: ["user", user.userId],
      queryFn: async () => {
        const res = await fetch(`${process.env.NEXT_PUBLIC_API_USER_URL}/user/${user.userId}`, {
          cache: "no-store",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!res.ok) {
          throw new Error("Failed to fetch user details");
        }
        const userData = await res.json();
        return userData?.data?.User;  // Assuming data format from response
      },
      enabled: !!user.userId,
    })),
  });

  // Combine online users with their detailed info
  const mergedData = (onlineUsers || []).map((onlineUser: any, index: number) => ({
    ...onlineUser,
    userDetails: userDetailsQueries[index]?.data || null,
  }));

  return {
    data: mergedData,
    isLoading: loadingOnlineUsers || userDetailsQueries.some(query => query.isLoading),
    error: onlineUsersError || userDetailsQueries.find(query => query.error)?.error,
    refetch,
  };
};