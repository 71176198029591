import { getToken } from "@/auth/config/keycloak"
import { useQuery } from "@tanstack/react-query"

export const useGetTemplatesByVendorId = (
  vendorId: string,
  conversationId?: string
) => {
  const token = getToken()

  const { data, isPending, error, refetch } = useQuery({
    queryKey: ["templates", vendorId, conversationId],
    queryFn: async () => {
      const url = `${process.env.NEXT_PUBLIC_API_SUPPORT_URL}/integration/template/${vendorId}`
      const res = await fetch(url, {
        cache: "no-store",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      if (!res.ok) {
        throw new Error("Failed to fetch integrations")
      }

      const data = await res.json()
      return data
    }
  })

  return {
    data,
    isPending,
    error,
    refetch
  }
}