interface UserMutationProps {
    vendorId: number;
    userId: number;
    metalType: string;
    tradeType: "BUY" | "SELL";
    baseMetalWeight: number;
    livePrice: number;
    pendingTradeRate: number;
    quantity: number;
    token: string;
}

export const openPendingTrades = async ({ userId, metalType, tradeType, baseMetalWeight, livePrice, pendingTradeRate, quantity, token, vendorId }: UserMutationProps) => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_STAGING}/opportunity/virtual-trading/open-pending`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'X-VendorId': vendorId.toString(),
        },
        body: JSON.stringify({
            userId,
            metalType,
            tradeType,
            baseMetalWeight,
            livePrice,
            pendingTradeRate,
            quantity,
            tickId: null,
        }),
    });

    if (!response.ok) {
        throw { status: response.status, response: response.statusText };
    }

    const data = await response.json();
    return data;
};