import { getToken } from "@/auth/config/keycloak"
import { useQueries, useQuery } from "@tanstack/react-query"

export const useOnlyUser = (ids: string[]) => {
  const token = getToken()

  // Fetch user by ID and then fetch user by phone number (if available)
  return useQueries({
    queries: ids?.map((id) => ({
      queryKey: ["user", id],
      queryFn: async () => {
        // Fetch user data by ID
        const res = await fetch(
          `${process.env.NEXT_PUBLIC_API_USER_URL}/user/${id}`,
          {
            cache: "no-store",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        if (!res.ok) {
          throw new Error("Failed to fetch user")
        }
        const userData = await res.json()
        const user = userData?.data?.User

        return user;
      },
      enabled: !!id,
    })),
  })
}
