import { getToken } from "@/auth/config/keycloak"
import { useMutation } from "@tanstack/react-query"

interface IDeletePendingTrades {
  vendorId: number
  id: number | null
  token: string
}

interface MutationOptionProps {
  onSuccess?: (data: any) => void
  onError?: (error: Error) => void
}

const deletePendingTrades = async ({
  vendorId,
  id,
  token,
}: IDeletePendingTrades) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_STAGING}/opportunity/virtual-trading/remove-pending-trade`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "X-VendorId": vendorId.toString(),
      },
      body: JSON.stringify({
        id,
      }),
    }
  )

  if (!response.ok) {
    throw { status: response.status, response: response.statusText }
  }

  const data = await response.json()
  return data
}

export const useDeletePendingTrades = (options: MutationOptionProps = {}) => {
  return useMutation({
    mutationFn: deletePendingTrades,
    onSuccess: options.onSuccess,
    onError: options.onError,
    retry: 3,
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000),
  })
}
