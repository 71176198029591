import { useQuery } from "@tanstack/react-query";

interface GetWhatsappByLengthProps {
    userId: number;
    vendorId: number;
    token: string;
    conversationId: string;
}


export const GetPendingTrades = ({ userId, vendorId, token, conversationId }: GetWhatsappByLengthProps) => {

    const { data, isPending, error, refetch } = useQuery({
        queryKey: ['pendingData', conversationId, userId, vendorId],
        queryFn: async () => {
            const url = `${process.env.NEXT_PUBLIC_API_STAGING}/opportunity/virtual-trading/pending-trades?userId=${userId}`
            const res = await fetch(url, {
                cache: 'no-store',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'X-VendorId': vendorId.toString(),
                },
            });

            if (!res.ok) {
                throw new Error('Failed to fetch integrations');
            }

            const data = await res.json();
            return data;
        },
        enabled: userId > 0,
    });

    return {
        data,
        isPending,
        error,
        refetch
    };
};