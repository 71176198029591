"use client"

import { useEffect, useState } from "react"
import { Plus, Search, SearchIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { useToast } from "@/components/ui/use-toast"

import { ConversationAddDialogNew } from "./conversation-add-dialog"
import { GetUsersByPhone } from "@/api_store/queries/user/getUserByPhone"
import { useSocket } from "@/auth/provider/SocketContext"
import { useKeycloak } from "@/auth/provider/KeycloakProvider"

export default function DynamicTabsCustom({
  tabs,
  conversationList,
  setConversationId,
  setSelectedUserInfo,
  setNewMessageSender,
  setNewMessageRecipient,
  setIsNewMessage,
  setAllConvs,
  setMyConvs,
  allConvsReset,
  myConvsReset,
  setMySide,
  setSelectedVendor,
  vendorQueries,
  myMessages
}) {
  const [openAdd, setOpenAdd] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedUserPhone, setSelectedUserPhone] = useState(null)
  const [selectedBusiness, setSelectedBusiness] = useState(null)
  const [userDataInfo, setUserDataInfo] = useState([])
  const [currentTab, setCurrentTab] = useState("allmessages")
  const [searchText, setSearchText] = useState("")
  const [selectedUserId, setSelectedUserId] = useState("");
  const { socket, status } = useSocket();

  const { user } = useKeycloak()
  const userId = user?.userId

  const { toast } = useToast()

  const {
    data: userData,
    isLoading,
    error,
    refetch: refetchUsers,
  } = GetUsersByPhone({ phone: selectedUserPhone })

  useEffect(() => {
    if (userData) {
      const user = userData?.data?.User;
      setUserDataInfo(user);
      setSelectedUserId(user?.id);
    }
  }, [userData]);

  const isValid = () => {
    return selectedBusiness?.length > 0 && selectedUser > 0
  }

  const handleChangeBusiness = (value) => {
    setSelectedBusiness(value);
  }

  const reset = () => {
    setSelectedUser("")
    setSelectedBusiness("")
  }

  const handleSubmit = () => {
    if (!isValid()) {
      toast({
        variant: "destructive",
        description: "Please fill all the fields!",
      })
      return
    }
    else if (!selectedUserId) {
      toast({
        variant: "destructive",
        description: "User not found in this system!",
      })
      return
    }
    const selectedConversation = conversationList?.find((conversation) => {
      const participants = conversation.userPart.conversation.participants
      return (
        participants[0].id === selectedBusiness &&
        participants[1].id === selectedUserId
      )
    })

    if (selectedConversation) {
      setConversationId(selectedConversation?.userPart?.conversation?._id)
      setMySide(selectedConversation?.userPart?.conversation?.participants?.[0]?.id)
      setOpenAdd(false)
      reset()
    } else {
      setConversationId("")
      setSelectedUserInfo(userDataInfo)
      setNewMessageSender(selectedBusiness)
      const selectedVendor = vendorQueries.find(a => a?.data?.id === selectedBusiness)?.data;
      setSelectedVendor(selectedVendor)
      setMySide(selectedBusiness)
      setNewMessageRecipient(selectedUserId)
      setOpenAdd(false)
      setIsNewMessage(true)
      reset()
    }
  }

  const handleSearch = (value) => {
    setSearchText(value)
    if (value.length <= 0) {
      setAllConvs(allConvsReset)
      setMyConvs(myConvsReset)
      return
    }
    const normalizedValue = value.toLowerCase().replace(/\s+/g, "")

    if (currentTab === "allmessages") {
      const dt = [...allConvsReset]
      const filteredData = dt?.filter((data) => {
        return (
          data?.fullName
            ?.toLowerCase()
            .replace(/\s+/g, "")
            .includes(normalizedValue) ||
          data?.initId
            ?.toLowerCase()
            .replace(/\s+/g, "")
            .includes(normalizedValue) ||
          data?.uid?.toString().replace(/\s+/g, "").includes(normalizedValue) ||
          data?.initUid
            ?.toLowerCase()
            .replace(/\s+/g, "")
            .includes(normalizedValue) ||
          data?.phone
            ?.toLowerCase()
            .replace(/\s+/g, "")
            .includes(normalizedValue)
        )
      })
      setAllConvs(filteredData)
    }
    else if (currentTab === "mymessages") {
      const dt = [...myConvsReset]
      const filteredData = dt?.filter((data) => {
        return (
          data?.fullName
            ?.toLowerCase()
            .replace(/\s+/g, "")
            .includes(normalizedValue) ||
          data?.initId
            ?.toLowerCase()
            .replace(/\s+/g, "")
            .includes(normalizedValue) ||
          data?.uid?.toString().replace(/\s+/g, "").includes(normalizedValue) ||
          data?.initUid
            ?.toLowerCase()
            .replace(/\s+/g, "")
            .includes(normalizedValue) ||
          data?.phone
            ?.toLowerCase()
            .replace(/\s+/g, "")
            .includes(normalizedValue)
        )
      })
      setMyConvs(filteredData)
    }
  }

  const onTabChange = (value) => {
    setCurrentTab(value)
    setSearchText("")
    handleSearch("")
  }

  useEffect(() => {
    if (!socket) return;
    socket.on("support-conversations", (data) => {
      const isMyMessage = myMessages?.some(b => b?.id === data?.conversation?._id);
      if (isMyMessage) {
        onTabChange("mymessages");
      }
    });

    socket.on("support-distribution", (data) => {
      if (data?.action == "distributed") {
        const isMyMessage = data?.InboxChunks?.[0]?.userId === userId;
        if (isMyMessage) {
          onTabChange("mymessages");
        }
      }
    });

    // Clean up event listener
    return () => {
      socket.off("support-conversations");
      socket.off("support-distribution");
    };
  }, [socket, status]);

  return (
    <Tabs
      onValueChange={onTabChange}
      defaultValue={tabs[1]?.value}
      className="flex h-full flex-col"
    >
      <div className="mx-4 mt-2 flex items-center justify-between">
        <TabsList
          className={`grid-cols-${tabs.length} grid w-full rounded-md border border-none`}
        >
          {tabs.map((tab) => (
            <TabsTrigger
              key={tab.value}
              value={tab.value}
              className="rounded-md"
            >
              {tab.label}
            </TabsTrigger>
          ))}
        </TabsList>

        <Button
          onClick={() => setOpenAdd(true)}
          variant="secondary"
          size="icon"
          className="ml-2"
        >
          <Plus className="h-4 w-4" />
        </Button>
      </div>

      <div className="relative mx-4 mt-4">
        <Input
          type="text"
          placeholder="Search here.."
          className="rounded-md border pr-10 focus-visible:border-gray-400 focus-visible:ring-0 focus-visible:ring-offset-0"
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center pr-3">
          <Search className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
      </div>

      <div className="flex-grow overflow-auto scrollbar-thin">
        {tabs.map(
          (tab) =>
            tab?.content && (
              <TabsContent key={tab.value} value={tab.value} className="h-full">
                {tab.content}
              </TabsContent>
            )
        )}
      </div>

      <ConversationAddDialogNew
        open={openAdd}
        close={() => setOpenAdd(false)}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        selectedBusiness={selectedBusiness}
        handleChangeBusiness={handleChangeBusiness}
        handleSubmit={handleSubmit}
        setUserDataInfo={setUserDataInfo}
        setSelectedUserPhone={setSelectedUserPhone}
      />
    </Tabs>
  )
}
