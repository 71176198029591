import { useMutation } from "@tanstack/react-query"

interface UserMutationProps {
  vendorId: number
  userId: number
  liveAskPrice: number
  liveBidPrice: number
  token: string
  tradeType: "BUY" | "SELL" | ""
}

export const closeAllTrades = async ({
  userId,
  token,
  vendorId,
  liveAskPrice,
  liveBidPrice,
  tradeType = "",
}: UserMutationProps) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_STAGING}/opportunity/virtual-trading/external/close-all`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "X-VendorId": `${vendorId}`,
      },
      body: JSON.stringify({
        userId,
        liveAskPrice,
        liveBidPrice,
        tradeType,
      }),
    }
  )

  if (!response.ok) {
    throw { status: response.status, response: response.statusText }
  }

  const data = await response.json()
  return data
}

interface MutationOptionProps {
  onSuccess?: (data: any) => void
  onError?: (error: any) => void
}

export const useCloseAllTrades = (options: MutationOptionProps = {}) => {
  return useMutation({
    mutationFn: closeAllTrades,
    onSuccess: options.onSuccess,
    onError: options.onError,
    retry: 3, // Retries 3 times on failure (default behavior)
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 20000),
  })
}
