import React from "react";
import { Trash } from "lucide-react";
import { Button } from "@/components/ui/button";

interface DeleteButtonProps {
  onResetRecording: () => void;
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({ onResetRecording }) => (
  <Button onClick={onResetRecording} size="icon" variant="destructive">
    <Trash size={15} />
  </Button>
);