import { getToken } from "@/auth/config/keycloak";
import { FSDataType } from "@/utils/filter-sort-param";
import { useQuery } from "@tanstack/react-query";

interface GetUsersByPhoneProps {
  phone: string;
}

export const GetUsersByPhone = ({ phone }: GetUsersByPhoneProps) => {
  const token = getToken();
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['userByPhone', phone],
    queryFn: async () => {
      const res = await fetch(`${process.env.NEXT_PUBLIC_API_USER_URL}/user/phone/+${phone}`, {
        cache: 'no-store',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      if (!res.ok) {
        throw new Error('Failed to fetch users');
      }

      const data = await res.json();
      return data;
    },
    enabled: !!phone,
  });

  return {
    data,
    isLoading,
    error,
    refetch
  };
};