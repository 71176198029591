interface UserMutationProps {
    token: string;
    tradeId: number;
    livePrice: number;
    executedPrice: number;
    vendorId: number;
}

export const closeActiveTrade = async ({ livePrice, token, tradeId, executedPrice, vendorId }: UserMutationProps) => {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_STAGING}/opportunity/virtual-trading/external/close`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'X-VendorId': `${vendorId}`
        },
        body: JSON.stringify({
            id: tradeId,
            livePrice,
            executedPrice,
            isMutated: false
        }),
    });

    if (!response.ok) {
        throw { status: response.status, response: response.statusText };
    }

    const data = await response.json();
    return data;
};